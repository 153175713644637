import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactFormComponent from '../../components/contact/ContactFormComponent';
import { useNavigate } from 'react-router-dom';

const AirTicket = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/contact-us');
    };
    return (
        <>
            <SEO title="Air Tickets" />
            <Layout>
                <BreadcrumbOne
                    title="Air Tickets"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Air Tickets"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                <div className="thumbnail mb--50 text-center">
                                    {/* <img className="radius-small w-100" src="/images/others/TOEFL.png" alt="Privacy Policy" /> */}
                                    <h2 className="title">~ Air Tickets ~</h2>
                                </div>

                                <p>Booking air tickets can be a daunting task, but we make it simple and stress-free. Our platform provides a range of options to suit your travel needs, ensuring you get the best deals available. Whether you’re flying domestically or internationally, we offer competitive prices and a user-friendly booking experience.</p>
                                
                                <h4 className="title">Why Book with Us?</h4>
                                <p>We are committed to providing you with the best deals on air tickets. Our platform allows you to compare prices, choose your preferred airlines, and book your flights with ease. We work with top airlines to bring you exclusive discounts and offers that you won’t find elsewhere.</p>

                                <h4 className="title">Tips for Booking Air Tickets</h4>
                                <ul>
                                    <li><strong>Book Early:</strong> Secure the best fares by booking your tickets well in advance.</li>
                                    <li><strong>Be Flexible:</strong> Consider alternative airports or flight times to find better deals.</li>
                                    <li><strong>Sign Up for Alerts:</strong> Stay informed about special deals and discounts by signing up for our alerts.</li>
                                    <li><strong>Book with Us:</strong> We guarantee the best deals on air tickets, ensuring you save on every booking.</li>
                                </ul>
                                <p>Whether you’re planning a vacation or a business trip, trust us to provide you with the most convenient and cost-effective booking options. Book your air tickets through our platform and experience a hassle-free journey from start to finish.</p>

                            
                               {/* <ContactFormComponent /> */}
                               <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                                    <button 
                                        className="rn-btn edu-btn mb--30" 
                                        type="button"
                                        onClick={() => handleClick()}>
                                        <span>Get in touch with us</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default AirTicket;