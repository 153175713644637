import React,{useState, useEffect} from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import CourseTypeThree from '../../components/course/CourseTypeThree';
import CourseData from '../../data/course/CourseData.json';
import axios from 'axios';
import { fetchGuestCourses } from '../../services/api';

const CourseCustom = () => {
    // const CourseItems = CourseData.slice(0, 9);
    const [courseItems, setCourseItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const formatCourseData = (apiResponse) => {
        // Extract the message array from the response
        const courses = apiResponse.message;
      
        // Map through each course in the message array and restructure the data
        const formattedCourses = courses.map((course, index) => ({
          id: index + 1, // Assigning a unique id
          title: course.title || 'No Title',
          image: 'house-uk.jpg',
        //   image: course.image || 'house-uk.jpg', temporary comment need this later on
          instructor: course.instructors.length ? course.instructors[0].full_name : 'Unknown Instructor', 
          duration: '3 - 4 months', 
          durationInHour: '3 Hours', 
          durationInHourMinute: '3hr 14min', 
          level: 'Available Now', 
          language: 'English', 
          deadline: '25 Dec, 2024', 
          rating: course.avg_rating || 0, 
          student: course.enrollment_count || 0, 
          lesson: course.lesson_count || 0, 
          quizzes: 12, 
          price: course.course_price, 
          oldPrice: '', 
          review: course.avg_rating || 0, 
          passPercentage: 0, 
          featured: course.featured === 1,
          certificate: 'not available', 
          filterParam: '', 
          categories: ['Language Learning', 'Business'], 
          videoLink: course.video_link ? [`https://www.youtube.com/watch?v=${course.video_link}`] : ['https://www.youtube.com/watch?v=pNje3bWz7V8'], // Use provided video link or default
          excerpt: course.short_introduction || 'No introduction available', // Short introduction or default text
          details: `<h5>Course Description</h5> ${course.description || ''}`, // Course description
        }));
      
        return formattedCourses;
      };

      const fetchCourses = async () => {
        try {
            setLoading(true);
            const response = await fetchGuestCourses();
            const formattedData = formatCourseData(response.data); 
            setCourseItems(formattedData); 
            setLoading(false);
            } catch (error) {
            console.error('Error fetching courses:', error);
            setCourseItems([]); 
            setLoading(false);
            } finally {
            setLoading(false);
            }
      };

      useEffect(() => {
        fetchCourses();
      }, [])
      
      
    return (
        <>
            {/* <SEO title="Course Style - 3" />
            <Layout>
                <BreadcrumbOne 
                    title="Course Style - 3"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Course Style - 3"
                /> */}
                <div className="edu-course-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5 mt--10">
                        <h3>Courses</h3>
                        {loading && <div class="d-flex align-items-center">
                            <strong role="status">Loading...</strong>
                            <div class="spinner-border ms-auto" aria-hidden="true"></div>
                                        </div>}
                            {!loading && courseItems &&
                                courseItems.map((item) => (
                                    <div className="col-12 col-sm-6 col-lg-4" key={ item.id }>
                                        <CourseTypeThree data={item} />
                                    </div>
                                ) )
                            }
                        </div>
                    </div>
                </div>
            {/* </Layout> */}
        </>
    )
}

export default CourseCustom;