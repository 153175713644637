import React, {useContext, useState, useEffect} from 'react';
import TrackVisibility from 'react-on-screen';
import CountUp from 'react-countup';
import Slider from 'react-slick';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { slugify } from '../../utils';
import { InstructorCourseSliderParams } from '../../utils/script';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Skill from '../../components/progressbar/ProgressbarItem';
import CourseTypeTwo from '../../components/course/CourseTypeTwo';
import InstructorData from '../../data/instructor/InstructorData.json';
import CourseData from '../../data/course/CourseData.json';
import { AuthContext } from '../../provider/AuthProvider';
import { fetchAvgCourseCompleted, fetchCourseCount, fetchProgress } from '../../services/api';

const ProfilePage = () => {
    const { state, logoutUser } = useContext(AuthContext);
    const { slug } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // Loader state
    const [progress, setProgress] = useState(null); // State for progress
    const [features, setFeatures] = useState([]);
    const [error, setError] = useState(null); // State for handling errors
  

    const logout = ()=>{
        logoutUser();
        navigate('/login');
    }

    const data = InstructorData.filter(instructor => slugify( instructor.name ) === slugify( 'James Carlson' ) );
    // const data = InstructorData.filter(instructor => slugify( instructor.name ) === slugify( slug ) );
    const teamMember = data[0];

    const courses = CourseData.map(course => {
        return {
            ...course,
            instructor: slugify(course.instructor) === slug ? course.instructor : ''
        }
    }).filter(course => course.instructor.length > 0);

    useEffect(() => {
        // Fetch all three APIs in parallel
        const fetchData = async () => {
          try {
            const [courseCountRes, progressRes, avgCourseCompletedRes] = await Promise.all([
              fetchCourseCount(),
              fetchProgress(),
              fetchAvgCourseCompleted(),
            ]);
    
            const { message: courseMessage } = courseCountRes.data;
            const { message: avgCourseCompletedMessage } = avgCourseCompletedRes.data;
            const isDecimal = avgCourseCompletedMessage % 1 !== 0;

        // Create the formatted features array
        const formattedFeatures = [
          {
            title: 'Course Allocated',
            number: courseMessage.course_allocates.toString(), 
          },
          {
            title: 'Total chapters',
            number: courseMessage.chapters.toString(),
          },
          {
            title: 'AVE. Completed Chapters',
            number: avgCourseCompletedMessage.toFixed(1), 
            isDecimal,
          },
        ];
            const { message: progressMessage } = progressRes.data;
            const transformedProgress = Object.entries(progressMessage).map(([title, value]) => {
                let progressColor;
                if (value >= 80) {
                  progressColor = 'green';
                } else if (value >= 40 && value <= 70) {
                  progressColor = 'yellow';
                } else {
                  progressColor = 'red';
                }
      
                return {
                  title, // e.g., "java", "python"
                  value, // e.g., 80, 60
                  progressColor, // e.g., "green", "yellow", "red"
                };
              });
      
              // Set the formatted progress data
              setProgress(transformedProgress);
              setFeatures(formattedFeatures);
          } catch (error) {
            setError('Failed to fetch data');
            console.error('API call error:', error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
      }, []);
    

    return (
        <>
            <SEO title={ teamMember.name } />
            <Layout>
                {/* <BreadcrumbOne 
                    title="Instructor Profile"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Instructor Profile"
                /> */}
                <div className="edu-instructor-profile-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-4 pr--55">
                                <div className="instructor-profile-left">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src={`${process.env.PUBLIC_URL}../images/instructor/instructor-01/profile.png`} alt="Team Member" />
                                        </div>
                                        <div className="content">
                                            <h5 className="title">{state.student_name}</h5>
                                            <span className="subtitle">Student</span>
                                            <div className="contact-with-info">
                                                <p><span>Email:</span> <a href={`mailto:${state.email}`}>{state.email}</a></p>
                                                <p><span>Phone:</span> <a href={`tel:${teamMember.phone}`}>{teamMember.phone}</a></p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8">
                                <div className="instructor-profile-right">
                                    <div className="inner">
                                        <div className="section-title text-start">
                                            <span className="pre-title">About { state.student_name }</span>
                                            <h3 className="title">Hello, I’m { state.student_name }</h3>
                                            <p className="description mt--40">{ teamMember.details }</p>
                                        </div>

                                        {loading && <div class="d-flex align-items-center">
                                            <strong role="status">Loading...</strong>
                                            
                                            <div class="spinner-grow text-secondary" role="status" aria-hidden="true"></div>
                                            <div class="spinner-grow text-success" role="status" aria-hidden="true"></div>
                                            <div class="spinner-grow text-primary" role="status" aria-hidden="true"></div>
                                        </div>}

                                        { !loading && features && progress && 
                                            <div className="edu-skill-style mt--65">
                                                <div className="section-title text-start mb--30">
                                                    <span className="pre-title">Course</span>
                                                    <h3 className="title">Courses Progress</h3>
                                                </div>
                                                <div className="rbt-progress-style-1 row g-5">
                                                    { progress.map((progressValue,id) => (
                                                        <div className="col-lg-6" key={id}>
                                                            <TrackVisibility
                                                                once
                                                                className="single-progress"
                                                            >
                                                                <Skill progress={progressValue} /> 
                                                            </TrackVisibility>
                                                        </div>
                                                    ) ) }
                                                </div>
                                            </div>
                                        }

                                        { features && features.length > 0 && 
                                            <div className="course-statistic-wrapper bg-color-primary ptb--50 mt--65 radius-small">
                                                <div className="row align-items-center g-5">
                                                    { features.map((item, i) => (
                                                        <div className="col-lg-4 colmd-6 col-12 line-separator" key={i}>
                                                            <div className="course-statistic text-center">
                                                                <div className="inner">
                                                                    <TrackVisibility once>
                                                                        { ( { isVisible } ) => isVisible && item.isDecimal !== true ? <span className="total"><CountUp end={item.number} duration={1} delay={.1} start={0} /></span> : ''
                                                                            
                                                                        }
                                                                    </TrackVisibility>
                                                                    <TrackVisibility once>
                                                                        { ( { isVisible } ) => isVisible && item.isDecimal === true ? <span className="total"><CountUp end={item.number} duration={ 1 } delay={.1} start={ 0 } decimals={ 1 } /></span> : ''
                                                                            
                                                                        }
                                                                    </TrackVisibility>
                                                                    <p>{ item.title }</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) ) }
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                                    <button 
                                        className="rn-btn edu-btn mb--30" 
                                        type="button"
                                        onClick={logout}>
                                        <span>Logout</span>
                                    </button>
                                </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default ProfilePage;