import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils';
import SectionTitle from '../sectionTitle/SectionTitle';

const   AboutUsThreeInstructor = () => {
    const [visible, setVisible] = useState( '' );
    return (
        
        <div className="eduvibe-about-three-team edu-instructor-area edu-section-gap bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes = "text-center"
                            slogan = "Our Team"
                            title = "Get to Know Our Educators"
                        />
                    </div>
                </div>
                <div className="row g-5 mt--20" style={{justifyContent:'center'}}>
                            
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team1' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team1' )} onMouseLeave={() => setVisible('')} style={{filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))'}}>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                    <img src="/images/instructor/instructor-03/Bhumika_Mam.png" alt="team images" />
                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title">
                                                Ms. Bhumika Dilkhush
                                                    </h5>
                                                <span className="desc">Director</span>
                                                {/* <div className="team-share-info bg-transparent">
                                                    <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                                    <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                                    <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                                    <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team5' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team5' )} onMouseLeave={() => setVisible('')} style={{filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))'}}>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                    <img src="/images/instructor/instructor-03/Gina.png" alt="team images" />
                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title">
                                                Ms. Aayati Dilkhush</h5>
                                                <span className="desc">Director</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team2' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team2' ) } onMouseLeave={() => setVisible('')} style={{filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))'}}>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail"> 
                                                    <img src="/images/instructor/instructor-03/Celina.png" alt="team images" />
                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title">
                                                Ms. Selina Velkani
                                                    </h5>
                                                <span className="desc">Application Head</span>
                                                {/* <div className="team-share-info bg-transparent">
                                                    <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                                    <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                                    <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                                    <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team3' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team3' ) } onMouseLeave={() => setVisible('')} style={{filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))'}}>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                
                                                    <img src="/images/instructor/instructor-03/Kajal_Bothra.png" alt="team images" />

                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title">
                                                Ms. Kajal Bothra
                                                    </h5>
                                                <span className="desc">Head of Accounts</span>
                                                {/* <div className="team-share-info bg-transparent">
                                                    <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                                    <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                                    <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                                    <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team4' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team4' ) }>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                    <img src="/images/instructor/instructor-03/Jainuddin.png" alt="team images" />
                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title">
                                                Mr. Jainuddin Shaikh
                                                    </h5>
                                                <span className="desc">Language Trainer</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team5' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team5' ) }>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                    <img src="/images/instructor/instructor-03/Ashish.png" alt="team images" />

                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title">
                                                Mr. Ashish Yadav
                                                </h5>
                                                <span className="desc">Language Trainer</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team6' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team6' ) }>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                    <img src="/images/instructor/instructor-03/Allahrakha.png" alt="team images" />
                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title">
                                                    
                                                Mr. Allahrakha Pathan
                                                    </h5>
                                                <span className="desc">TCA, Surat</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team7' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team7' ) } onMouseLeave={() => setVisible('')} style={{filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))'}}>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail">
                                               
                                                    <img src="/images/instructor/instructor-03/Zohra.png" alt="team images" />
                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title">
                                                Ms. Zohra Visani</h5>
                                                <span className="desc">TCA - Rajkot</span>
                                                {/* <div className="team-share-info bg-transparent">
                                                    <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                                    <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                                    <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                                    <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team8' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team8' ) }>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                
                                                    <img src="/images/instructor/instructor-03/Karim.png" alt="team images" />
                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title">
                                                Mr. Karim Visani</h5>
                                                <span className="desc">TCA - Rajkot</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                    </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-25-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-03.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="/images/shapes/shape-15-05.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsThreeInstructor;