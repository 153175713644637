import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactMeForm from '../../components/contact/ContactMeForm';
import ContactFormComponent from '../../components/contact/ContactFormComponent';
import { useNavigate } from 'react-router-dom';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import CustomCounter from '../../components/counterup/CustomCounter';
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout';

const Selt = () => {
    const navigate = useNavigate();
    const seltItems = [
        {
            title: 'Thorough Exam Coverage',
            numberOfCourses: 'Complete preparation for all SELT components.',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'Expert Guidance',
            numberOfCourses: 'Professional coaching from seasoned instructors.',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'Resource-Rich Environment',
            numberOfCourses: 'Access to top-notch study materials and tools.',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'Proven Success Track',
            numberOfCourses: 'A strong record of helping students achieve their UK visa goals.',
            icon: 'icon-Class',
            link: '#'
        }
    ];
    const seltBenefits = [
        {
            title: 'Thorough Exam Coverage',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Expert Guidance',
            icon: 'icon-presentation',
            number: 100,
            suffix: '%'
        },
        {
            title: 'Resource-Rich Environment',
            icon: 'icon-trophy',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Proven Success Track',
            icon: 'icon-Open-book',
            number: 300,
            suffix: '+'
        }
    ];
    const handleClick = () => {
        navigate('/contact-us');
    };
    return (
        <>
            <SEO title="SELT" />
            <Layout>
                <BreadcrumbOne
                    title="SELT"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="SELT"
                />
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={'SELT'}
                    mTitle={'Skills For English'}
                    desc_1={'At FETC, we offer specialized SELT coaching designed to help candidates excel in their language proficiency assessments, a crucial step for UK visa applications. Our coaching program is meticulously crafted to cover all aspects of the SELT exams, ensuring that students are thoroughly prepared to meet the stringent standards set by the UKVI. With expert guidance, our experienced instructors focus on enhancing each student’s abilities, boosting their confidence and overall exam performance.'}
                    desc_2={'Our center is committed to providing a supportive and comprehensive learning environment where students can access high-quality resources and professional coaching. We understand the importance of these exams for your future, and our mission is to equip you with the skills and knowledge needed to succeed. At FETC, we are dedicated to helping you achieve your goals and secure the visa necessary to study, work, or live in the UK.'}
                    // subTitle={usaData.subTitle}
                    imgSrc={'/images/others/SELT_training.png'}

                />
                {/* <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white"> */}
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12" style={{marginBottom:'20px'}}>
                                        <SectionTitle
                                                classes="text-center"
                                                slogan=""
                                                title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    {/* <CategoryThreeCustom
                                        items={seltItems}
                                        customStyle={{justifyContent:'center'}}
                                    /> */}
                                    <CustomCounter
                                        funFacts={seltBenefits} />



                                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none" style={{marginTop:'20px'}}>
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-17.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div>

                                <h4 className="title">Exam Format and Structure</h4>
                                <p>The SELT exam is meticulously designed to assess the English language proficiency of non-native speakers across four key areas: Reading, Writing, Speaking, and Listening. Each section is carefully structured to measure different facets of your English skills, ensuring a comprehensive evaluation. The exam is conducted exclusively in a computer-based format, providing a standardized, secure, and reliable testing experience for all candidates.</p>

                                <h4 className="title">Who Needs to Take SELT?</h4>
                                <p>The SELT is a mandatory requirement for those applying for I-JK visas, including student visas, work visas, and settlement purposes. It is a critical step for individuals who need to prove their English proficiency as part of their visa application process. Whether you are pursuing educational opportunities, employment, or long-term residency in the UK, the SELT exam is an essential component of your application.</p>


                                <h4 className="title">Accepted Countries and Cost</h4>
                                <p>The SELT is recognized by the UK government and is a requirement for various visa categories. It is also accepted by educational institutions and employers in the UK, making it a crucial test for those aiming to study, work, or settle in the United Kingdom. The cost of the SELT exam is approximately INR 15,000, reflecting its significance in achieving your academic, professional, or immigration goals.</p>

                            
                               {/* <ContactFormComponent /> */}
                               <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                                    <button 
                                        className="rn-btn edu-btn mb--30" 
                                        type="button"
                                        onClick={() => handleClick()}>
                                        <span>Get in touch with us</span>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </Layout>
        </>
    )
}
export default Selt;