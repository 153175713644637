import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactMeForm from '../../components/contact/ContactMeForm';
import ContactFormComponent from '../../components/contact/ContactFormComponent';
import { useNavigate } from 'react-router-dom';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout';
import CustomCounter from '../../components/counterup/CustomCounter';

const PTEVersantExam = () => {
    const navigate = useNavigate();
    const ptevItems = [
        {
            title: 'Comprehensive Assessment',
            numberOfCourses: 'Evaluate your Listening & Speaking skills.',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'Rapid Results',
            numberOfCourses: 'Get prompt feedback to make informed decisions swiftly.',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'Efficiency and Quality',
            numberOfCourses: 'Save time and resources with fast, high-quality assessments.',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'Objective Scoring',
            numberOfCourses: 'Rely on unbiased, accurate assessments that reflect true proficiency.',
            icon: 'icon-Class',
            link: '#'
        }
    ];
    const pteBenefits = [
        {
            title: 'Comprehensive Assessment',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Rapid Results',
            icon: 'icon-presentation',
            number: 100,
            suffix: '%'
        },
        {
            title: 'Efficiency and Quality',
            icon: 'icon-trophy',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Objective Scoring',
            icon: 'icon-Open-book',
            number: 300,
            suffix: '+'
        }
    ];

    const handleClick = () => {
        navigate('/contact-us');
    };
    return (
        <>
            <SEO title="PTEVersant" />
            <Layout>
                <BreadcrumbOne
                    title="PTE - Versant"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="PTEVersant"
                />
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={'PTE - Versant'}
                    mTitle={'Pearson Versant Test'}
                    desc_1={"At FETC, we offer the Pearson Versant Test, a leading exam that provides a swift and accurate assessment of your English proficiency across key areas: Listening, Speaking, Writing, and Reading. Whether you're an individual aiming to measure your English skills or an organization seeking reliable language assessments, the Pearson Versant Test is your go-to solution."}
                    desc_2={"Choosing FETC as your testing center ensures a smooth and professional experience. Our well-equipped facilities and dedicated staff are here to support you at every step, making sure your assessment is conducted in a stress-free environment. The Pearson Versant Test is designed to deliver precise results quickly, helping you make informed decisions for personal growth or organizational excellence."}
                    // subTitle={usaData.subTitle}
                    imgSrc={'/images/others/Pearson_Versant_exam.png'}
                />
                {/* <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white"> */}
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">


                                <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12" style={{marginBottom:'20px'}}>
                                        <SectionTitle
                                                classes="text-center"
                                                slogan=""
                                                title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    <CustomCounter
                                        funFacts={pteBenefits} />



                                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none" style={{marginTop:'20px'}}>
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-17.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div>

                               <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                                    <button 
                                        className="rn-btn edu-btn mb--30" 
                                        type="button"
                                        onClick={() => handleClick()}>
                                        <span>Get in touch with us</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </Layout>
        </>
    )
}
export default PTEVersantExam;