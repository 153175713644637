import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const FeatureItems = [
    {
        title: 'Expert Guidance',
        info: 'Our dedicated team of experienced professionals is here to offer you personalized support at every stage of your journey, ensuring that you receive the insights and advice needed to navigate your path with confidence.',
        icon: 'icon-Destination',
        shape: 'shape-bg-1',
        link: '#'
    },
    {
        title: 'Comprehensive Solutions',
        info: 'Whether you’re preparing for exams, navigating the visa process, or planning your study abroad experience, we provide an all-encompassing suite of services designed to cover every aspect of your pursuit of success.',
        icon: 'icon-Browser',
        shape: 'shape-bg-2',
        link: '#'
    },
    {
        title: 'Cost-Effective Excellence',
        info: 'Take advantage of our exclusive discount vouchers and competitive pricing, making world-class services accessible without compromising on quality.',
        icon: 'icon-Lock',
        shape: 'shape-bg-3',
        link: '#'
    },
    {
        title: 'Track Record of Success',
        info: 'Join the ranks of hundreds of clients who have turned their aspirations into reality through our expert guidance, proven strategies, and unwavering commitment to their goals.',
        icon: 'icon-Settings',
        shape: 'shape-bg-4',
        link: '#'
    }
];

const CategoryCustomised = ( { wrapperClass, bgColor, alignment, style } ) => {
    return (
        <div className={ `row eduvibe-about-one-service ${ wrapperClass || 'g-5 mt--20' }` }>
            { FeatureItems.map( ( data , i ) => (
                <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className={ `col-lg-3 col-md-6 col-12${ style ? ' '+style : '' }` }
                    animateOnce={ true }
                    key={ i }
                >
                    <div className={ `service-card service-card-3 ${ alignment ? ' ' + alignment : 'text-left' } ${ data.shape } ${ bgColor || '' } ` } style={{ height:'460px',filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))'}}>
                        <div className="inner">
                            <div className="icon text-center" style={{display:'flex'}}>
                                <a href={ data.link }>
                                    <i className={ data.icon }></i>
                                </a>
                            </div>
                            <div className="content">
                                <h6 className="title text-center"><a href={ data.link }>{ data.title }</a></h6>
                                <p className="description text-center" style={{fontSize:'16px'}}>{ data.info }</p>
                                {/* <div className="read-more-btn">
                                    <a className="btn-transparent sm-size heading-color" href={ data.link }>Learn More<i className="icon-arrow-right-line-right"></i></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
        </div>
    )
}

export default CategoryCustomised;