import React, { useState, useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactFormComponent from '../../components/contact/ContactFormComponent';
import { useNavigate } from 'react-router-dom';
 
const faqCategories = [
    {
        id: 1,
        slug: 'work-faqs',
        label: 'WORK FAQs'
    },
    {
        id: 2,
        slug: 'study-faqs',
        label: 'STUDY FAQs'
    },
    {
        id: 3,
        slug: 'visa-faqs',
        label: 'VISA FAQs'
    },
    {
        id: 4,
        slug: 'settle-faqs',
        label: 'SETTLE FAQs'
    }
];

const generalFaqs = {
    leftColumn: [
        {
            id: 1,
            title: 'What services do you offer?',
            body: 'We provide a comprehensive suite of study abroad services, including personalized counselling to help you choose the right program, expert assistance with university applications and visa processing, and pre-departure orientation. Our support continues with post-arrival assistance to ensure a smooth transition to your new environment.'
        },
        {
            id: 2,
            title: 'Why should I choose your consultancy over others?',
            body: "Our consultancy is distinguished by a high success rate and a team of experienced advisors who offer customized guidance tailored to your needs. We have established strong partnerships with prestigious universities worldwide and provide ongoing support throughout your study abroad journey to ensure your success."
        },
        {
            id: 3,
            title: 'Do you charge for your services?',
            body: 'Yes, we charge a fee for our services, which encompasses personalized support and expert guidance throughout the application and visa process. We provide detailed information about our fees during your initial consultation.'
        },
        {
            id: 4,
            title: 'Do you assist in visa dates?',
            body: 'Yes, we do.'
        },
        {
            id: 5,
            title: 'How far in advance should I start the application process?',
            body: 'We recommend starting the application process 6 to 12 months before your intended start date. This allows sufficient time for research, test preparation, application submission, financial planning, and visa processing.'
        }
    ],
    rightColumn: [
        {
            id: 1,
            title: 'How long is a work permit typically valid for, and can it be renewed?',
            body: 'The validity of a work permit varies depending on the country and type of employment but is typically issued for 1 to 3 years. Many work permits can be renewed before they expire, provided you continue to meet the eligibility criteria. We offer assistance with both initial applications and renewals to ensure uninterrupted employment.'
        },
        {
            id: 2,
            title: 'What are the general eligibility criteria for obtaining a work permit?',
            body: 'Eligibility for a work permit generally includes having a valid job offer, relevant qualifications, and sometimes proof of language proficiency. Specific criteria depend on the country and type of work permit. We assist in determining your eligibility and navigating the application process.'
        },
        {
            id: 3,
            title: 'How much does it cost to apply for a work permit?',
            body: 'The cost of applying for a work permit varies by country and can include government fees, medical exams, and document preparation costs. We provide detailed information on the expected expenses and help you budget for the application process.'
        },
        {
            id: 4,
            title: 'Are there any financial requirements to obtain a work permit?',
            body: 'Some countries require proof of financial stability or sufficient income to support yourself during your stay. This may include showing bank statements, salary slips, or a contract from your employer. We guide you through these requirements to ensure your application meets all necessary criteria.'
        }
    ]
}
const workFaqs = {
    leftColumn: [
        {
            id: 1,
            title: 'What are the general requirements for obtaining a work permit in Europe?',
            body: 'The requirements for a European work permit vary by country but typically include a valid job offer from a European employer, proof of qualifications, and sometimes proof of sufficient financial means. Specific requirements depend on the country and type of work permit. We can provide detailed information based on your destination and job role.'
        },
        {
            id: 2,
            title: 'How long does it take to process work permit for Europe?',
            body: "The processing time for a European work permit can range from few weeks to several months, depending on the country and type of permit It's advisable to apply in advance of your intended start date. We assist with navigating the process to ensure timely submission and reduce delays."
        },
        {
            id: 3,
            title: 'What is the difference between a work permit and a work visa?',
            body: 'A work permit is an official document that allows a foreign national to work in a specific country, while a work visa is the authorization to enter and stay in that country for employment purposes. In most cases, both a work permit and a work visa are required to legally work abroad. We can guide you through the application process for both documents.'
        },
        {
            id: 4,
            title: 'Can I change employers if I have a work permit?',
            body: 'The ability to change employers depends on the type of work permit you hold. Some work permits are tied to a specific employer, while others may allow for more flexibility. If you wish to change employers, it may require applying for a new work permit. We can help you understand the options available based on your current permit.'
        }
    ],
    rightColumn: [
        {
            id: 1,
            title: 'How long is a work permit typically valid for, and can it be renewed?',
            body: 'The validity of a work permit varies depending on the country and type of employment but is typically issued for 1 to 3 years. Many work permits can be renewed before they expire, provided you continue to meet the eligibility criteria. We offer assistance with both initial applications and renewals to ensure uninterrupted employment.'
        },
        {
            id: 2,
            title: 'What are the general eligibility criteria for obtaining a work permit?',
            body: 'Eligibility for a work permit generally includes having a valid job offer, relevant qualifications, and sometimes proof of language proficiency. Specific criteria depend on the country and type of work permit. We assist in determining your eligibility and navigating the application process.'
        },
        {
            id: 3,
            title: 'How much does it cost to apply for a work permit?',
            body: 'The cost of applying for a work permit varies by country and can include government fees, medical exams, and document preparation costs. We provide detailed information on the expected expenses and help you budget for the application process.'
        },
        {
            id: 4,
            title: 'Are there any financial requirements to obtain a work permit?',
            body: 'Some countries require proof of financial stability or sufficient income to support yourself during your stay. This may include showing bank statements, salary slips, or a contract from your employer. We guide you through these requirements to ensure your application meets all necessary criteria.'
        }
    ]
}

const studyFaqs = {
    leftColumn: [
        {
            id: 1,
            title: 'How do I start the application process?',
            body: 'To begin the application process, schedule a consultation with one of our experienced advisors. We will evaluate your academic background, financial situation, career objectives, and preferences to guide you in selecting the most suitable programs and universities for your goals.'
        },
        {
            id: 2,
            title: 'What documents are required for the application?',
            body: 'The typical documents required include academic transcripts, financial statements, a statement of purpose, letters of recommendation, a resume, standardized test scores (if applicable), and proof of language proficiency. Specific requirements may vary depending on the program and university.'
        },
        {
            id: 3,
            title: 'Do you assist with writing the Statement of Purpose (SOP) and essays?',
            body: 'Yes, we provide professional assistance in crafting your Statement of Purpose (SOP) and essays. Our team helps you develop compelling and personalized content that highlights your strengths and aspirations, ensuring your application stands out.'
        },
        {
            id: 4,
            title: 'How much does studying abroad cost?',
            body: 'The cost of studying abroad varies based on the country, university, and program. Expenses typically include tuition fees, accommodation, living costs, insurance, and travel. We provide detailed cost estimates during your consultation to help you plan effectively.'
        },
        {
            id: 5,
            title: 'Do you offer preparation services for foreign language proficiency tests like IELTS or TOEFL?',
            body: 'Yes, we offer coaching for IELTS, TOEFL, SAT, SELT, and PTE. As an ETS registered provider, we provide comprehensive test preparation and career counseling services to help you achieve your desired scores and advance your academic and professional goals.'
        },
        {
            id: 6,
            title: 'Are there any financial requirements for obtaining a student visa? **',
            body: 'Yes, most countries require proof of sufficient funds to cover tuition fees, living expenses, and other costs during your study period. This is a crucial part of the student visa application process. We help you understand and meet these financial requirements to ensure a smooth visa approval.'
        }
    ],
    rightColumn: [
        {
            id: 1,
            title: 'Are there scholarships or financial aid available?',
            body: 'Yes, many universities offer scholarships and financial aid opportunities. We assist you in identifying suitable scholarships and financial aid options and guide you through the application process to help manage your expenses.'
        },
        {
            id: 2,
            title: 'Can you help me choose the right program and university?',
            body: 'Yes, our advisors have extensive knowledge of programs and universities globally. We will work closely with you to align your interests, academic background, and career goals with the best program and university options.'
        },
        {
            id: 3,
            title: 'How can I improve my chances of getting accepted into my desired program?',
            body: 'To improve your acceptance chances, focus on crafting a strong application with a compelling Statement of Purpose, solid letters of recommendation, and a robust academic record. Our advisors provide personalized guidance to enhance these elements and help you present a standout application.'
        },
        {
            id: 4,
            title: 'Do you help with applications for both undergraduate and postgraduate programs? ',
            body: 'Absolutely. We provide assistance with applications for undergraduate, postgraduate, and doctoral programs across a wide range of disciplines and fields of study.'
        },
        {
            id: 5,
            title: 'What are the eligibility criteria for studying abroad? ',
            body: 'Eligibility criteria for studying abroad typically include academic qualifications, language proficiency, and standardized test scores like IELTS, TOEFL, or SAT. Specific requirements vary by country and program. We assist in assessing your eligibility and guiding you through the necessary preparations.'
        },
        {
            id: 6,
            title: 'How much does it cost to apply to universities abroad? ',
            body: 'The cost of applying to universities abroad includes application fees, standardized test fees, and costs for document preparation like transcripts and translations. Additionally, there may be expenses for visa applications and travel. We provide a detailed cost breakdown during your consultation to help you budget effectively.'
        }
    ]
}

const visaFaqs = {
    leftColumn: [
        {
            id: 1,
            title: 'How do you assist with the visa application process?',
            body: 'We offer comprehensive support for the visa application process, including guidance on visa requirements, preparation of necessary documents, and mock visa interviews to ensure you are thoroughly prepared.'
        },
        {
            id: 2,
            title: 'What if my visa application is denied?',
            body: 'While we maintain a high success rate, if your visa application is denied, we will review the reasons for the denial, help address any issues, and guide you through the reapplication process to improve your chances of success.'
        },
        {
            id: 3,
            title: 'How do I book a visa appointment for the USA?',
            body: "To book a USA visa appointment, you need to create an account on the US. Department of State's wise appointment website. We provide step-by-step assistance with scheduling your appointment and ensuring you have ell required documents prepared for your interview."
        },
        {
            id: 4,
            title: 'What are the common reasons for visa application rejections, and how can I avoid them?',
            body: 'Common reasons for visa rejections include incomplete documentation, insufficient financial proof, or discrepancies in the application. To mitigate these issues, ensure all documents are complete and accurate, provide clear financial evidence, and follow our detailed instructions throughout the process.'
        }
    ],
    rightColumn: [
        {
            id: 1,
            title: 'How can I prepare effectively for my visa interviews?',
            body: 'Effective preparation for a visa interview includes understanding the common questions asked, practicing your responses, and ensuring you have all necessary documents organized. We provide personalized coaching to help you rehearse key questions, refine your answers, and present your case confidently during the interview.'
        },
        {
            id: 2,
            title: 'What should I do if there are no available dates for a USA visa appointment?',
            body: 'If no dates are available, you can frequently check for new slots as cancellations occur. Additionally, we offer guidance on alternative options and strategies to expedite your appointment, including emergency appointment requests if applicable.'
        },
        {
            id: 3,
            title: 'What are some common mistakes to avoid during a visa interview?',
            body: 'Common mistakes include providing inconsistent or incomplete information, failing to demonstrate genuine intent, and being unprepared for questions about your background or plans. We help you avoid these pitfalls by conducting mock interviews and offering feedback to ensure you present a clear and consistent narrative.'
        }
    ]
}
const settleFaqs = {
    leftColumn: [
        {
            id: 1,
            title: 'What are the general eligibility criteria for settling abroad?',
            body: 'Eligibility criteria for settling abroad typically include factors like age, language proficiency, work experience, and qualifications. Some countries also have points-based systems or require specific skills that are in demand. We provide personalized assessments to determine your eligibility based on the country you wish to settle in.'
        },
        {
            id: 2,
            title: 'How much does it cost to apply for permanent residency or settlement?',
            body: 'The cost of applying for permanent residency or settlement includes application fees, medical exams, language tests, and document verification. There may also be additional costs for legal assistance and government processing fees. We offer guidance on the total expected expenses and help you plan accordingly.'
        },
        {
            id: 3,
            title: 'Are there financial requirements for settling abroad?',
            body: 'Yes, many countries require proof of financial stability, such as savings, income, or a job offer, to ensure you can support yourself and your dependents. This is often a critical part of the settlement application. We assist you in understanding and meeting these financial requirements to enhance your chances of approval.'
        }
    ],
    rightColumn: [
       
    ]
}
        
const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext( AccordionContext );
    const decoratedOnClick = useAccordionButton( eventKey );
    const isCurrentEventKey = activeEventKey === eventKey;
    return <button type="button" onClick={ decoratedOnClick } aria-expanded={ isCurrentEventKey ? true : false } className="edu-accordion-button">{ children }</button>
}

const Faq = ( { wrapperClass } ) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/contact-us');
    };
    const [selectedItem, setSelectedItem] = useState( 'work-faqs' );
    const [activeId1, setActiveId1] = useState( '0' );
    const [activeId2, setActiveId2] = useState( '0' );
    const [activeId3, setActiveId3] = useState( '0' );
    const [activeId4, setActiveId4] = useState( '0' );
    const [activeId5, setActiveId5] = useState( '0' );
    const [activeId6, setActiveId6] = useState( '0' );

    function toggleActive1( id ) {
        if (activeId1 === id) {
            setActiveId1(null);
        } else {
            setActiveId1(id);
        }
    }
    function toggleActive2( id ) {
        if (activeId2 === id) {
            setActiveId2(null);
        } else {
            setActiveId2(id);
        }
    }
    function toggleActive3( id ) {
        if (activeId3 === id) {
            setActiveId3(null);
        } else {
            setActiveId3(id);
        }
    }
    function toggleActive4( id ) {
        if (activeId4 === id) {
            setActiveId4(null);
        } else {
            setActiveId4(id);
        }
    }
    function toggleActive5( id ) {
        if (activeId5 === id) {
            setActiveId5(null);
        } else {
            setActiveId5(id);
        }
    }
    function toggleActive6( id ) {
        if (activeId6 === id) {
            setActiveId6(null);
        } else {
            setActiveId6(id);
        }
    }

    return (
        <>
            <SEO title="Frequently Asked Questions" />
            <Layout>
                <BreadcrumbOne 
                    title="Frequently Asked Questions"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="FAQ"
                />
                <div className={ `edu-accordion-area accordion-shape-1 edu-section-gap bg-color-white ${ wrapperClass ? wrapperClass : '' } ` }>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="pre-title">FaQ Questions</span>
                                    <h3 className="title">Get Every General Answers <br /> From Here</h3>
                                </div>
                            </div>
                        </div>

                            <div className="row g-5 align-items-center mt--20">
                                <div className="col-lg-12">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                generalFaqs.leftColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive5(i.toString())} bsPrefix={`edu-accordion-item ${activeId5 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        

                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="edu-tab-nav nav nav-tabs mt--40">
                                    {
                                        faqCategories.map( ( button ) => (
                                            <li className="nav-item" key={ button.id }>
                                                <button
                                                    className={ button.slug === selectedItem ? 'nav-link active' : 'nav-link'}
                                                    type="button"
                                                    aria-label={button.label}
                                                    onClick={ () => setSelectedItem( button.slug ) }
                                                >
                                                    { button.label }
                                                </button>
                                            </li>
                                        ) )
                                    }
                                </ul>
                            </div>
                        </div>
                        { selectedItem === 'work-faqs' && 
                            <div className="row g-5 align-items-center mt--20">
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                workFaqs.leftColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive1(i.toString())} bsPrefix={`edu-accordion-item ${activeId1 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                workFaqs.rightColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive2(i.toString())} bsPrefix={`edu-accordion-item ${activeId2 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        }

                        { selectedItem === 'study-faqs' && 
                            <div className="row g-5 align-items-center mt--20">
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                studyFaqs.leftColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive3(i.toString())} bsPrefix={`edu-accordion-item ${activeId3 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                studyFaqs.rightColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive4(i.toString())} bsPrefix={`edu-accordion-item ${activeId4 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        }
                        { selectedItem === 'visa-faqs' && 
                            <div className="row g-5 align-items-center mt--20">
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                visaFaqs.leftColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive3(i.toString())} bsPrefix={`edu-accordion-item ${activeId3 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                visaFaqs.rightColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive4(i.toString())} bsPrefix={`edu-accordion-item ${activeId4 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        }

                        { selectedItem === 'settle-faqs' && 
                            <div className="row g-5 align-items-center mt--20">
                                <div className="col-lg-12">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                settleFaqs.leftColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive5(i.toString())} bsPrefix={`edu-accordion-item ${activeId5 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {/* <ContactFormComponent /> */}
                <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                    <button
                        className="rn-btn edu-btn mb--30"
                        type="button"
                        onClick={() => handleClick()}>
                        <span>Get in touch with us</span>
                    </button>
                </div>
            </Layout>
        </>
    )
}

export default Faq;