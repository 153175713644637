import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactMeForm from '../../components/contact/ContactMeForm';
import ContactFormComponent from '../../components/contact/ContactFormComponent';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import CustomCounter from '../../components/counterup/CustomCounter';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { useNavigate } from 'react-router-dom';
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout';

const VisitorVisa = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/contact-us');
    };

    const items = [
        {
            title: 'Valid Passport',
            numberOfCourses: 'Ensure your passport is valid for at least six months beyond your intended stay.',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'Visa Application Form',
            numberOfCourses: 'Completed and signed application form, as per the guidelines of the destination country.',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'Proof of Travel',
            numberOfCourses: 'Documents like travel itineraries, flight bookings, and accommodation details.',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'Financial Evidence',
            numberOfCourses: 'Bank statements or proof of financial stability to cover your expenses during your stay.',
            icon: 'icon-Class',
            link: '#'
        },
        {
            title: 'Invitation Letter',
            numberOfCourses: 'If visiting family or friends, an invitation letter from your host may be required.',
            icon: 'icon-Setting',
            link: '#'
        },
        {
            title: 'Passport Size Photo',
            numberOfCourses: "Passport-sized photos as per the country's specifications.",
            icon: 'icon-Mind',
            link: '#'
        },
    ];
    const visitorBenefits = [
        {
            title: 'VISA CONSULTATION',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        },
        {
            title: 'EARLIEST VISA SLOTS ',
            icon: 'icon-trophy',
            number: 100,
            suffix: '%'
        },
        {
            title: 'INTERVIEW PREPARATION',
            icon: 'icon-Open-book',
            number: 300,
            suffix: '+'
        },
        {
            title: 'FINANCIAL GUIDANCE',
            icon: 'icon-presentation',
            number: 150,
            suffix: '+'
        }
    ];

    return (
        <>
            <SEO title="Visitor Visa" />
            <Layout>
                <BreadcrumbOne 
                    title="Visitor Visa"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Visitor Visa"
                />
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={'Visa'}
                    mTitle={'Visitor Visa'}
                    desc_1={"A Visitor Visa opens the door to exploring new countries, visiting loved ones, or attending important events abroad. Designed for short stays, this visa allows you to experience the culture, landmarks, and hospitality of your chosen destination. The application process is straightforward, requiring essential documents like a valid passport, proof of funds, and sometimes a letter of invitation. With a successful application, you gain the freedom to immerse yourself in the sights and sounds of a new environment, making memories that will last a lifetime."}
                    desc_2={"The cost of a visitor visa varies depending on the country, but the experience it offers is invaluable. Whether you're planning a family reunion, a dream vacation, or a business trip, securing a visitor visa is the first step toward your journey. With careful preparation and timely application, you can look forward to a hassle-free process and the exciting adventures that await you abroad."}
                    // subTitle={usaData.subTitle}
                    imgSrc={'/images/others/SELT.jpeg'}
                />
                {/* <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white"> */}
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">

                                <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <SectionTitle
                                                classes = "text-center"
                                                slogan = ""
                                                title = "Common Visitor Visa Requirements"
                                            />
                                        </div>
                                    </div>

                                    <CategoryThreeCustom
                                        items={items}
                                    />


                                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div>
                                <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <SectionTitle
                                                classes = "text-center"
                                                slogan = ""
                                                title = "Our Services"
                                            />
                                        </div>
                                    </div>

                                    <CustomCounter
                                        funFacts={visitorBenefits}
                                    />


                                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                        <div className="shape-image shape-image-1">
                                            <img src="/images/shapes/shape-19-02.png" alt="Shape Thumb" />
                                        </div>

                                    </div>
                                </div>


                            
                                {/* <ContactFormComponent /> */}
                                <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                                    <button 
                                        className="rn-btn edu-btn mb--30" 
                                        type="button"
                                        onClick={() => handleClick()}>
                                        <span>Get in touch with us</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </Layout>
        </>
    )
}
export default VisitorVisa;