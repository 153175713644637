import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const PteGeneral = () => {
    return (
        <>
            <SEO title="PteGeneral" />
            <Layout>
                <BreadcrumbOne 
                    title="PteGeneral"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="PteGeneral"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                <div className="thumbnail mb--50 text-center">
                                    <h2 className="title">~ PTE - General Test Levels ~</h2>
                                </div>
                                
                                <p className="text-center">All six levels of proficiency (A1, 1, 2, 3, 4 and 5) are designed to assess and accredit general English language ability. The levels are linked to the Common European Framework of Reference for Languages (CEFR) and accredited by Ofqual in the UK.</p>
            
                                <h4 className="title">International Certificate levels</h4>
                                <ol>
                                    <li> <strong> Level 1 </strong></li>

                                    <p><strong>Who is it for?</strong></p>
                                    <p>This test is for test takers with a beginner knowledge of English, sufficient for survival in social, travel and everyday situations. Test takers will be expected to understand very short, simple information and to express themselves simply and briefly, in both speech and in writing.</p>
                                    
                                    <p><strong>How is it structured?</strong></p>
                                    <p>The test has a written paper and a spoken test. The written paper is divided into nine tasks which are linked to a theme.  At Level 1 Foundation the theme tends to relate to familiar and routine matters such as hobbies, home, transport, and school. </p>
                                    
                                    <p><strong>Spoken test</strong></p>
                                    <p>This is a 3.5 minute session consisting of a timed interaction between a test taker and an interlocutor. </p>
                                    <p>There are two sections to the test:</p>
                                    <ol>
                                        <li>describe picture</li>
                                        <li>role play</li>
                                    </ol>
                                </ol>
                                <ol start={'2'}>
                                    <li> <strong> Level 2 </strong></li>

                                    <p><strong>Who is it for?</strong></p>
                                    <p>This test is for test takers with intermediate English for practical use in study, work, travel and other everyday purposes. Test takers will be expected to understand and express in speaking, and writing information, ideas, feelings, opinions and common functions about everyday situations in straightforward spoken and written language. </p>
                                    
                                    <p><strong>How is it structured?</strong></p>
                                    <p>The test has a written paper and a spoken test. The written paper is divided into nine tasks which are linked to a theme. At Level 2 Intermediate the theme tends to relate to familiar and routine matters such as tourism, entertainment, travel, and education. </p>
                                    
                                    <p><strong>Spoken test</strong></p>
                                    <p>This is a 5.5 minute session, consisting of a timed interaction between a test taker and an interlocutor.</p>
                                    <p>There are three sections to the test.</p>
                                    <ol>
                                        <li>discussion</li>
                                        <li>describe picture</li>
                                        <li>roleplay</li>
                                    </ol>
                                </ol>
                                <ol start={'3'}>
                                    <li> <strong> Level 3 </strong></li>

                                    <p><strong>Who is it for?</strong></p>
                                    <p>This test is for test takers with intermediate English for practical use in study, work, travel and other everyday purposes. Test takers will be expected to understand and express in speaking, and writing information, ideas, feelings, opinions and common functions about everyday situations in straightforward spoken and written language. </p>
                                    
                                    <p><strong>How is it structured?</strong></p>
                                    <p>The test has a written paper and a spoken test. The written paper is divided into nine tasks which are linked to a theme. At Level 3 Intermediate the theme tends to relate to familiar and routine matters, such as home and shopping. </p>
                                    
                                    <p><strong>Spoken test</strong></p>
                                    <p>This is a 5.5 minute session, consisting of a timed interaction between a test taker and an interlocutor.</p>
                                    <p>There are three sections to the test.</p>
                                    <ol>
                                        <li>discussion</li>
                                        <li>describe picture</li>
                                        <li>roleplay</li>
                                    </ol>
                                </ol>
                                <ol start={'4'}>
                                    <li> <strong> Level 4 </strong></li>

                                    <p><strong>Who is it for?</strong></p>
                                    <p>This test is for test takers who are applying for higher education in an English speaking institution and/or are wishing to demonstrate an advanced level in English for social, academic and vocational purposes.</p>
                                    <p>Test takers will be expected to understand the explicit and implicit meaning of an extensive range of complex, written and spoken communication in social, academic and professional contexts, and to express experiences, opinions and attitudes accurately, appropriately, flexibly and effectively in the spoken and written form. </p>
                                    
                                    <p><strong>How is it structured?</strong></p>
                                    <p>The test has a written paper and a spoken test. The written paper is divided into nine tasks which are linked to a theme. At Level 4 the theme tends to be quite abstract, such as global issues like pollution and conservation.</p>
                                    
                                    <p><strong>Spoken test</strong></p>
                                    <p>This is a 6 minute session, consisting of a timed interaction between a test taker and an interlocutor.</p>
                                    <p>There are three sections to the test.</p>
                                    <ol>
                                        <li>discussion</li>
                                        <li>describe picture</li>
                                        <li>roleplay</li>
                                    </ol>
                                </ol>

                                <ol start={'5'}>
                                    <li> <strong> Level 5 </strong></li>

                                    <p><strong>Who is it for?</strong></p>
                                    <p>This test is for test takers who are applying for higher education in an English-speaking institution and/or are wishing to demonstrate a high level of proficiency in English for social, academic and professional purposes.</p>
                                    <p>Test takers will be expected to understand stated and unstated functions and the explicit and implicit meaning of the content of written and spoken communication in a wide range of social, academic and professional contexts, and to express themselves in the spoken and written form effectively with a high degree of accuracy, appropriacy and style. </p>
                                    
                                    <p><strong>How is it structured?</strong></p>
                                    <p>The test has a written paper and a spoken test. The written paper is divided into nine tasks which are linked to a theme. At Level 5 the theme tends to be quite abstract, such as global issues like pollution and conservation.</p>
                                    
                                    <p><strong>Spoken test</strong></p>
                                    <p>This is a 6 minute session, consisting of a timed interaction between a test taker and an interlocutor.</p>
                                    <p>There are three sections to the test.</p>
                                    <ol>
                                        <li>discussion</li>
                                        <li>describe picture</li>
                                        <li>roleplay</li>
                                    </ol>
                                </ol>
                                    
                                <div class="row">
                                    <div class="col">
                                        <button 
                                            className="rn-btn edu-btn w-100 mb--30" 
                                            type="button"
                                            onClick={() => window.open('https://qualifications.pearson.com/en/qualifications/international-certificate/international-certificate/test-levels.html', '_blank')}>
                                            <span>View More Information</span>
                                        </button>
                                    </div>
                                    <div class="col">
                                        <button 
                                            className="rn-btn edu-btn w-100 mb--30" 
                                            type="button"
                                            onClick={() => window.open('https://qualifications.pearson.com/en/home.html', '_blank')}>
                                            <span>Online Registration PTE - General</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default PteGeneral;