import React,
{ useState, useContext } from 'react';
import { login } from '../../services/api';
import Cookies from 'js-cookie';
import { AuthContext } from '../../provider/AuthProvider';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const { setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            const data = await login(email, password);
            setUser(data.message);
            navigate('/profile')

        } catch (error) {
            console.error('Login failed:', error);
            setError('Login failed. Please check your credentials and try again.');
        }
    };

    return (
        <div className="login-form-box">
            <h3 className="mb-30">Login</h3>
            <form className="login-form" onSubmit={handleLogin}>
                <div className="input-box mb--30">
                    <input 
                        type="text" 
                        placeholder="Username or Email"
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}  />
                </div>
                <div className="input-box mb--30">
                    <input 
                        type="password" 
                        placeholder="Password"
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="comment-form-consent input-box mb--30">
                    <input id="checkbox-1" type="checkbox" />
                    <label htmlFor="checkbox-1">Remember Me</label>
                </div>
                <button className="rn-btn edu-btn w-100 mb--30" type="submit">
                    <span>Login</span>
                </button>
                {error && <p style={{color: 'red'}}>{error}</p>}
                {/* <div className="input-box">
                    <a href="#" className="lost-password">Lost your password?</a>
                </div> */}
            </form>
        </div>
    )
}

export default LoginForm;