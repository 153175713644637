import React from 'react'
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout'
import CustomCounter from '../../components/counterup/CustomCounter'
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import { Navigate } from 'react-router-dom';

export const AboutCountryEurope = () => {

    const europeBenefits = [
        {
            title: 'Diverse Educational Systems',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Cultural Immersion',
            icon: 'icon-trophy',
            number: 100,
            suffix: '%'
        },
        {
            title: 'Wide Range of Programs',
            icon: 'icon-Open-book',
            number: 300,
            suffix: '+'
        },
        {
            title: 'Innovative Research Opportunities',
            icon: 'icon-presentation',
            number: 150,
            suffix: '+'
        }
    ];
    const handleClick = () => {
        Navigate('/contact-us');
    };

    const europeItems = [
        {
            title: 'Swiss Business School',
            numberOfCourses: '',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'ESEI International business school of Barcelona',
            numberOfCourses: '',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'ICN Business School ',
            numberOfCourses: '',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'Excelia Business School ',
            numberOfCourses: '',
            icon: 'icon-Class',
            link: '#'
        },
        {
            title: 'Rennes School of Business',
            numberOfCourses: '',
            icon: 'icon-Setting',
            link: '#'
        },
        {
            title: 'College De Paris ',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Burgundy School of Business',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'University of Lyon',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'JAMK University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Kozminski University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Lazarski University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Sapienza University of Rome',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        }
    ];

    const europeData = {
        title: 'Europe',
        mTitle: 'Why Study in Europe?',
        desc_1: "Europe offers a unique and enriching educational experience, with its countries providing a diverse array of academic programs that reflect the continent’s rich cultural and intellectual history. Whether you’re drawn to the arts in Italy, engineering in Germany, or business in France, Europe’s universities are renowned for their excellence and global perspective. Studying in Europe allows you to immerse yourself in a variety of cultures and languages, broadening your worldview and enhancing your personal and academic growth.",
        desc_2: 'The European education system is characterized by a strong emphasis on research, innovation, and practical application, preparing students for success in an increasingly interconnected world. With many universities offering programs in English, Europe is an accessible and attractive destination for international students. The continent’s interconnectedness also means that you can easily travel and experience different cultures, making your time in Europe not only academically rewarding but also personally enriching.',
        subTitle: 'Features of Europe',
        funFacts: europeBenefits,
        items: europeItems
    }
    return (
        <>
            <SEO title={`About ${europeData.title}`} />
            <Layout>
                <BreadcrumbOne
                    title="Study Abroad"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Study Abroad"
                />
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={europeData.title}
                    mTitle={europeData.mTitle}
                    desc_1={europeData.desc_1}
                    desc_2={europeData.desc_2}
                    subTitle={europeData.subTitle}
                    imgSrc={'/images/about/about-07/europe.png'}
                />
                <CustomCounter
                    funFacts={europeData.funFacts}
                />

                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                classes="text-center"
                                slogan=""
                                title="Universities"
                            />
                        </div>
                    </div>

                    <CategoryThreeCustom
                        items={europeData.items}
                    />


                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                    <button
                        className="rn-btn edu-btn mb--30"
                        type="button"
                        onClick={() => handleClick()}>
                        <span>Get in touch with us</span>
                    </button>
                </div>

            </Layout>

        </>
    )
}
