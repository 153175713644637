import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactMeForm from '../../components/contact/ContactMeForm';
import ContactFormComponent from '../../components/contact/ContactFormComponent';
import { useNavigate } from 'react-router-dom';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import CustomCounter from '../../components/counterup/CustomCounter';
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout';

const PTE_A = () => {
    const navigate = useNavigate();
    const pteaItems = [
        {
            title: 'Complete Preparation',
            numberOfCourses: 'Comprehensive coverage of all sections with targeted training.',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'Exam-Specific Strategies',
            numberOfCourses: 'Techniques to navigate the PTE-A format effectively.',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'Regular Practice',
            numberOfCourses: 'Ongoing mock tests paired with actionable feedback.',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'Expert Guidance',
            numberOfCourses: 'Support from experienced instructors committed to your success.',
            icon: 'icon-Class',
            link: '#'
        }
    ];

    const pteaBenefits = [
        {
            title: 'Complete Preparation',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Exam-Specific Strategies',
            icon: 'icon-presentation',
            number: 100,
            suffix: '%'
        },
        {
            title: 'Regular Practice',
            icon: 'icon-trophy',
            number: 550,
            suffix: '+'
        },
        {
            title: '⁠Expert Guidance',
            icon: 'icon-Open-book',
            number: 300,
            suffix: '+'
        }
    ];
    const handleClick = () => {
        navigate('/contact-us');
    };
    return (
        <>
            <SEO title="PTE_A" />
            <Layout>
                <BreadcrumbOne
                    title="PTE - A"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="PTE-A"
                />
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={'Pearson'}
                    mTitle={'PTE - A'}
                    desc_1={'At FETC, our PTE-A coaching program is designed to equip students with the skills and confidence needed to excel. The PTE-A is a vital exam for non-native English speakers who wish to study, work, or live in English-speaking countries. Our program comprehensively covers all sections of the PTE-A exam: Speaking, Writing, Reading, and Listening, ensuring you are thoroughly prepared for each aspect of the test.'}
                    desc_2={"Our coaching approach includes in-depth lessons, frequent practice tests, and personalized feedback to help you master the PTE-A exam. We emphasize strategic learning techniques tailored to the PTE-A's unique format, enabling you to achieve the high scores required by universities and employers globally. At FETC, our dedicated instructors provide the support and expertise necessary to help you succeed in your PTE-A journey."}
                    // subTitle={usaData.subTitle}
                    imgSrc={'/images/others/PTE_Training.png'}

                />
                {/* <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white"> */}
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                                            
                                {/* <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12">
                                        <SectionTitle
                                                classes="text-center"
                                                slogan=""
                                                title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    <CategoryThreeCustom
                                        items={pteaItems}
                                        customStyle={{justifyContent:'center'}}
                                    />


                                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div> */}

                                <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12" style={{marginBottom:'20px'}}>
                                        <SectionTitle
                                                classes="text-center"
                                                slogan=""
                                                title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    <CustomCounter
                                        funFacts={pteaBenefits} />



                                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none" style={{marginTop:'20px'}}>
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-17.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div>


                                <h4 className="title">Exam Format and Structure</h4>
                                <p>PTE-A consists of three main sections: Speaking & Writing, Reading, and Listening. Each section
                                    evaluates different aspects of your English language skills, particularly in an academic context. The
                                    test is entirely computer-based, with the speaking section utilizing advanced Al technology to assess
                                    your proficiency. This format ensures a fair and consistent evaluation for all candidates.
                                    </p>

                                <h4 className="title">Who Needs to Take PTE-A?</h4>
                                <p>PTE-A is widely accepted by universities, colleges, and governments around the world as proof of
                                    English language proficiency. It is ideal for non-native English speakers applying for higher education
                                    or seeking visa approvals in English-speaking countries. The test's quick results turnaround is also
                                    beneficial for those with tight application deadlines.
                                    </p>


                                <h4 className="title">Accepted Countries and Cost</h4>

                                <p>PTE-A is recognized by institutions in the USA, UK, Australia, Canada, and more. The cost of the PTE-
                                    A exam is approximately INR 15,900, making it a convenient and accessible choice for students and
                                    professionals aiming to study or work abroad
                                    </p>

                            
                               {/* <ContactFormComponent /> */}
                               <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                                    <button 
                                        className="rn-btn edu-btn mb--30" 
                                        type="button"
                                        onClick={() => handleClick()}>
                                        <span>Get in touch with us</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </Layout>
        </>
    )
}
export default PTE_A;