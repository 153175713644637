import React from 'react'
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout'
import CustomCounter from '../../components/counterup/CustomCounter'
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import { Navigate } from 'react-router-dom';

export const AboutCountryIreland = () => {

    const irelandBenefits = [
        {
            title: 'Rich Academic Tradition',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Innovative Learning Environment',
            icon: 'icon-trophy',
            number: 100,
            suffix: '%'
        },
        {
            title: 'High-Quality Education',
            icon: 'icon-presentation',
            number: 150,
            suffix: '+'
        },
        {
            title: 'Cultural and Historical Heritage',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        }
    ];

    const irelandItems = [
        {
            title: 'Griffith College',
            numberOfCourses: '',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'Trinity College Dublin',
            numberOfCourses: '',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'Maynooth University',
            numberOfCourses: '',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'National College of Ireland',
            numberOfCourses: '',
            icon: 'icon-Class',
            link: '#'
        },
        {
            title: 'TU Dublin',
            numberOfCourses: '',
            icon: 'icon-Setting',
            link: '#'
        },
        {
            title: 'University of Ireland Galway',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'University of Limerick',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Dublin Business School',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Atlantic Technological University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Technological University of Shannon',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'University College Dublin',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'South East University of Technology',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        }
    ];
    const handleClick = () => {
        Navigate('/contact-us');
    };

    const irelandData = {
        title: 'Ireland',
        mTitle: 'Why Study in Ireland?',
        desc_1: "Ireland, known as the Land of Saints and Scholars, has a rich educational tradition that dates back centuries. Today, it stands as a modern hub of innovation and learning, attracting students from all over the globe. Ireland's universities are renowned for their research and teaching excellence, offering a wide array of programs that cater to diverse academic interests. The country’s commitment to education is evident in its strong emphasis on creating an inclusive and supportive environment for international students.",
        desc_2: 'Beyond the classroom, Ireland’s vibrant cultural scene, friendly people, and breathtaking landscapes provide an enriching experience that goes hand in hand with academic pursuits. Whether you’re drawn to the humanities, sciences, or cutting-edge technology, studying in Ireland offers you the chance to learn in a country where tradition meets innovation, preparing you for a successful career anywhere in the world.',
        subTitle: 'Features of Ireland',
        funFacts: irelandBenefits,
        items: irelandItems
    }
    return (
        <>
            <SEO title={`About ${irelandData.title}`} />
            <Layout>
                <BreadcrumbOne
                    title="Study Abroad"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Study Abroad"
                />
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={irelandData.title}
                    mTitle={irelandData.mTitle}
                    desc_1={irelandData.desc_1}
                    desc_2={irelandData.desc_2}
                    subTitle={irelandData.subTitle}
                    imgSrc={'/images/about/about-07/Ireland1.png'}
                />
                <CustomCounter
                    funFacts={irelandData.funFacts}
                />

                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                classes="text-center"
                                slogan=""
                                title="Universities"
                            />
                        </div>
                    </div>

                    <CategoryThreeCustom
                        items={irelandData.items}
                    />


                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                                    <button 
                                        className="rn-btn edu-btn mb--30" 
                                        type="button"
                                        onClick={() => handleClick()}>
                                        <span>Get in touch with us</span>
                                    </button>
                                </div>

            </Layout>

        </>
    )
}
