import React, { createContext, useReducer, useEffect } from 'react';
import Cookies from 'js-cookie';

const AuthContext = createContext();

const initialState = {
    token: null,
    student_name: null,
    email: null,
};

const authReducer = (state, action) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                ...action.payload,
            };
        case 'LOGOUT_USER':
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {
        const token = Cookies.get('authToken');
        const student_name = Cookies.get('student_name');
        const email = Cookies.get('email');
        console.log('Token from cookies:', token);

        if (token && student_name && email) {
            dispatch({
                type: 'SET_USER',
                payload: { token, student_name, email }
            });
        }
    }, []);

    const setUser = (userData) => {
        Cookies.set('authToken', userData.token, { expires: 7 });
        Cookies.set('student_name', userData.student_name, { expires: 7 });
        Cookies.set('email', userData.email, { expires: 7 });
        dispatch({ type: 'SET_USER', payload: userData });
    };

    const logoutUser = () => {
        Cookies.remove('authToken');
        Cookies.remove('student_name');
        Cookies.remove('email');
        dispatch({ type: 'LOGOUT_USER' });
    };

    return (
        <AuthContext.Provider value={{ state, setUser, logoutUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext };
