import React from 'react'
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout'
import CustomCounter from '../../components/counterup/CustomCounter'
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import { Navigate } from 'react-router-dom';

export const AboutCountryUk = () => {

    const ukBenefits = [
        {
            title: 'Prestigious Universities',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Historical Academic Excellence',
            icon: 'icon-trophy',
            number: 100,
            suffix: '%'
        },
        {
            title: 'Diverse Cultural Experience',
            icon: 'icon-presentation',
            number: 150,
            suffix: '+'
        },
        {
            title: 'Global Career Opportunities',
            icon: 'icon-presentation',
            number: 550,
            suffix: '+'
        }
    ];

    const handleClick = () => {
        Navigate('/contact-us');
    };

    const ukItems = [
        {
            title: 'ESE London',
            numberOfCourses: '',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'Coventry University',
            numberOfCourses: '',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'Aston University',
            numberOfCourses: '',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'University of Bedfordshire',
            numberOfCourses: '',
            icon: 'icon-Class',
            link: '#'
        },
        {
            title: 'University of Greenwich',
            numberOfCourses: '',
            icon: 'icon-Setting',
            link: '#'
        },
        {
            title: 'University of Liverpool',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'University of East London',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Dublin Business School',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'De Mont Fort University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'University of East Anglia',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Anglia Ruskin University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'London School of Business',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        }
    ];

    const ukData = {
        title: 'UK',
        mTitle: 'Why Study in UK?',
        desc_1: "The United Kingdom is home to some of the oldest and most prestigious universities in the world, offering a rich academic heritage that is matched by a commitment to cutting-edge research and innovation. Studying in the UK provides you with the opportunity to learn from some of the brightest minds in your field, in an environment that fosters intellectual curiosity and critical thinking. Whether at a historic institution or a modern university, the UK’s academic offerings are diverse, catering to a wide range of interests and career paths.",
        desc_2: 'The UK’s global reputation for education extends beyond its rigorous academics. It’s a country that values diversity, with campuses that are vibrant, multicultural, and inclusive. Outside of your studies, you’ll have the chance to explore the UK’s rich cultural history, from its bustling cities to its picturesque countryside. A degree from a UK university not only enhances your knowledge and skills but also opens doors to global career opportunities, making it a worthwhile investment in your future.',
        subTitle: 'Features of UK',
        funFacts: ukBenefits,
        items: ukItems
    }
    return (
        <>
            <SEO title={`About ${ukData.title}`} />
            <Layout>
                <BreadcrumbOne
                    title="Study Abroad"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Study Abroad"
                />
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={ukData.title}
                    mTitle={ukData.mTitle}
                    desc_1={ukData.desc_1}
                    desc_2={ukData.desc_2}
                    subTitle={ukData.subTitle}
                    imgSrc={'/images/about/about-07/uk1.png'}

                />
                <CustomCounter
                    funFacts={ukData.funFacts}
                />

                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                classes="text-center"
                                slogan=""
                                title="Universities"
                            />
                        </div>
                    </div>

                    <CategoryThreeCustom
                        items={ukData.items}
                    />


                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                    <button
                        className="rn-btn edu-btn mb--30"
                        type="button"
                        onClick={() => handleClick()}>
                        <span>Get in touch with us</span>
                    </button>
                </div>

            </Layout>

        </>
    )
}
