import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactMeForm from '../../components/contact/ContactMeForm';
import ContactFormComponent from '../../components/contact/ContactFormComponent';
import { useNavigate } from 'react-router-dom';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout';
import CustomCounter from '../../components/counterup/CustomCounter';

const SeltExam = () => {
    const navigate = useNavigate();
    const seltItems = [
        {
            title: 'Exclusive Authorized Center',
            numberOfCourses: 'The only official test center in Gujarat.',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'Advanced Facilities',
            numberOfCourses: 'Cutting-edge technology for a smooth testing experience.',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'Comprehensive Exam Support',
            numberOfCourses: 'Guidance from registration to exam day.',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'Professional Environment',
            numberOfCourses: 'Adhering to strict exam integrity and security standards.',
            icon: 'icon-Class',
            link: '#'
        }
    ];
    const seltBenefits = [
        {
            title: 'Exclusive Authorized Center',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Advanced Facilities',
            icon: 'icon-presentation',
            number: 100,
            suffix: '%'
        },
        {
            title: 'Comprehensive Exam Support',
            icon: 'icon-trophy',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Professional Environment',
            icon: 'icon-Open-book',
            number: 300,
            suffix: '+'
        }
    ];
    const handleClick = () => {
        navigate('/contact-us');
    };
    return (
        <>
            <SEO title="SELT" />
            <Layout>
                <BreadcrumbOne
                    title="SELT"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="SELT"
                />
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={'SELT'}
                    mTitle={'Skills For English'}
                    desc_1={"At FETC, we take pride in being the only authorized test centre in the entire state of Gujarat, offering an unparalleled opportunity for candidates to take their exams in a professional and supportive environment. Our center is fully equipped with state-of-the-art facilities and adheres to the highest standards of exam integrity and security. Whether you're taking a language proficiency test for studying abroad, work, or immigration purposes, FETC ensures a seamless testing experience with personalized attention and expert guidance."}
                    desc_2={"Our commitment to excellence has made us the go-to destination for individuals across Gujarat who are pursuing their international education and career goals. As the sole authorized center, we provide a wide range of exams, including PTE, TOEFL, and others, giving you the convenience of accessing these crucial assessments right here in your home state. With FETC, you can trust that you are in the best hands to achieve the scores you need to succeed."}
                    // subTitle={usaData.subTitle}
                    imgSrc={'/images/others/SELT_Exam.png'}
                />
                {/* <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white"> */}
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">

                                {/* <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12">
                                        <SectionTitle
                                                classes="text-center"
                                                slogan=""
                                                title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    <CategoryThreeCustom
                                        items={seltItems}
                                        customStyle={{justifyContent:'center'}}
                                    />


                                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div> */}

                                <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12" style={{marginBottom:'20px'}}>
                                        <SectionTitle
                                                classes="text-center"
                                                slogan=""
                                                title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    <CustomCounter
                                        funFacts={seltBenefits} />



                                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none" style={{marginTop:'20px'}}>
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-17.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div>

                                {/* <h4 className="title">Exam Format and Structure</h4>
                                <p>The SELT exam is meticulously designed to assess the English language proficiency of non-native speakers across four key areas: Reading, Writing, Speaking, and Listening. Each section is carefully structured to measure different facets of your English skills, ensuring a comprehensive evaluation. The exam is conducted exclusively in a computer-based format, providing a standardized, secure, and reliable testing experience for all candidates.</p>

                                <h4 className="title">Who Needs to Take SELT?</h4>
                                <p>The SELT is a mandatory requirement for those applying for I-JK visas, including student visas, work visas, and settlement purposes. It is a critical step for individuals who need to prove their English proficiency as part of their visa application process. Whether you are pursuing educational opportunities, employment, or long-term residency in the UK, the SELT exam is an essential component of your application.</p>


                                <h4 className="title">Accepted Countries and Cost</h4>
                                <p>The SELT is recognized by the UK government and is a requirement for various visa categories. It is also accepted by educational institutions and employers in the UK, making it a crucial test for those aiming to study, work, or settle in the United Kingdom. The cost of the SELT exam is approximately INR 15,000, reflecting its significance in achieving your academic, professional, or immigration goals.</p> */}

                            
                               {/* <ContactFormComponent /> */}
                               <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                                    <button 
                                        className="rn-btn edu-btn mb--30" 
                                        type="button"
                                        onClick={() => handleClick()}>
                                        <span>Get in touch with us</span>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </Layout>
        </>
    )
}
export default SeltExam;