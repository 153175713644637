import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import { useNavigate } from 'react-router-dom';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import CustomCounter from '../../components/counterup/CustomCounter';
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout';

const SAT = () => {
    const navigate = useNavigate();
    const satItems = [
        {
            title: '⁠Complete SAT Preparation',
            numberOfCourses: 'Comprehensive training across Reading, Writing, and Math sections.',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: '⁠Strategic Learning',
            numberOfCourses: 'Tailored techniques to optimize performance in each section.',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: '⁠Regular Practice',
            numberOfCourses: 'Frequent mock exams with individualized feedback.',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: '⁠Expert Guidance',
            numberOfCourses: 'Instruction and support from experienced educators committed to your success.',
            icon: 'icon-Class',
            link: '#'
        }
    ];
    const satBenefits = [
        {
            title: '⁠Complete SAT Preparation',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Strategic Learning',
            icon: 'icon-presentation',
            number: 100,
            suffix: '%'
        },
        {
            title: 'Regular Practice',
            icon: 'icon-trophy',
            number: 550,
            suffix: '+'
        },
        {
            title: '⁠Expert Guidance',
            icon: 'icon-Open-book',
            number: 300,
            suffix: '+'
        }
    ];

    const handleClick = () => {
        navigate('/contact-us');
    };
    return (
        <>
            <SEO title="SAT" />
            <Layout>
                <BreadcrumbOne
                    title="SAT Coaching at FETC"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="SAT"
                />
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={'SAT'}
                    mTitle={'Scholastic Assessment Test'}
                    desc_1={'At FETC, our SAT (Scholastic Assessment Test) coaching program is thoughtfully crafted to help students excel in this pivotal college admission exam. The SAT is a key component for students aiming to secure admission to top universities worldwide. Our coaching program covers all critical sections of the SAT exam: Evidence-Based Reading, Writing and Language, and Math, ensuring comprehensive preparation.'}
                    desc_2={'Our approach integrates focused academic instruction with strategic test-taking techniques, offering extensive practice tests and detailed feedback to enhance your skills. We emphasize understanding the exam structure, mastering the core concepts, and applying effective strategies to boost your score. At FETC, our experienced educators are dedicated to supporting your SAT journey, providing the resources and guidance necessary to achieve your college aspirations.'}
                    // subTitle={usaData.subTitle}
                    imgSrc={'/images/others/SAT_training.png'}

                />
                {/* <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white"> */}
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">

                                {/* <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <SectionTitle
                                                classes="text-center"
                                                slogan=""
                                                title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    <CategoryThreeCustom
                                        items={satItems}
                                        customStyle={{justifyContent:'center'}}
                                    />


                                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div> */}

                                <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12" style={{marginBottom:'20px'}}>
                                        <SectionTitle
                                                classes="text-center"
                                                slogan=""
                                                title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    <CustomCounter
                                        funFacts={satBenefits} />



                                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none" style={{marginTop:'20px'}}>
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-17.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div>

                                <h4 className="title">Exam Format and Structure</h4>
                                <p>The SAT consists of two main sections: Evidence-Based Reading and Writing, and Math. Additionally, there is an optional Essay section. The test is designed to assess your readiness for college by evaluating your critical thinking, problem-solving, and analytical skills. The SAT is a standardized test, meaning every student faces the same questions, ensuring a fair and consistent measure of academic abilities.</p>

                                <h4 className="title">Who Needs to Take SAT?</h4>
                                <p>The SAT is typically required for undergraduate admissions by colleges and universities in the United States and many other countries. It is an essential exam for students seeking to demonstrate their academic strengths and college readiness. High SAT scores can also increase eligibility for scholarships and merit-based financial aid.</p>


                                <h4 className="title">Accepted Countries and Cost</h4>
                                <p>The SAT is accepted by institutions in the USA. The cost of the SAT exam is approximately INR 11,000, depending on whether you choose to take the optional Essay section, making it an important step in your journey to higher education.</p>


                            
                               {/* <ContactFormComponent /> */}
                               <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                                    <button 
                                        className="rn-btn edu-btn mb--30" 
                                        type="button"
                                        onClick={() => handleClick()}>
                                        <span>Get in touch with us</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </Layout>
        </>
    )
}
export default SAT;
