import React from 'react';
import ContactMeForm from './ContactMeForm';

function ContactFormComponent( ) {


    return (
        <div className="eduvibe-contact-me-top edu-contact-me-area edu-section-gap bg-color-white">
        <div className="container eduvibe-animated-shape">
            <div className="row g-5">
                <div className="col-lg-6">
                    <div className="pr--75 pr_lg--0 pr_md--0 pr_sm--0">
                        <div className="thumbnail">
                            <img className="w-100" src="/images/contact/contact-me/contactus.jpg" alt="Contact Me" />

                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="section-title text-start mb--45">
                        <span className="pre-title">Contact Us</span>
                        <h3 className="title">Send Us Your Requirement</h3>
                    </div>
                    <ContactMeForm formStyle="rnt-contact-form rwt-dynamic-form row" />
                </div>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div className="shape-image shape-image-1">
                    <img src="/images/shapes/shape-03-11.png" alt="Shape Thumb" />
                </div>
                <div className="shape-image shape-image-2">
                    <img src="/images/shapes/shape-15-06.png" alt="Shape Thumb" />
                </div>
                <div className="shape-image shape-image-3">
                    <img src="/images/shapes/shape-09-03.png" alt="Shape Thumb" />
                </div>
                <div className="shape-image shape-image-4">
                    <img src="/images/shapes/shape-03-02.png" alt="Shape Thumb" />
                </div>
            </div>
        </div>
    </div>
    )
}
export default ContactFormComponent;