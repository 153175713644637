import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactFormComponent from '../../components/contact/ContactFormComponent';
import { useNavigate } from 'react-router-dom';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import CustomCounter from '../../components/counterup/CustomCounter';
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout';

const Gmat = () => {
    const navigate = useNavigate();
    const gmatItems = [
        {
            title: 'Complete GMAT Preparation',
            numberOfCourses: 'Detailed training for all exam sections.',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'Strategic Focus',
            numberOfCourses: 'Techniques to navigate and excel in the GMAT’s structure.',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'Continuous Practice',
            numberOfCourses: 'Regular mock exams with personalized feedback.',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'Professional Guidance',
            numberOfCourses: 'Instruction and support from experienced trainers.',
            icon: 'icon-Class',
            link: '#'
        }
    ];
    const gmatBenefits = [
        {
            title: 'Complete GMAT Preparation',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Strategic Focus',
            icon: 'icon-presentation',
            number: 100,
            suffix: '%'
        },
        {
            title: 'Continuous Practice',
            icon: 'icon-trophy',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Professional Guidance',
            icon: 'icon-Open-book',
            number: 300,
            suffix: '+'
        }
    ];

    const handleClick = () => {
        navigate('/contact-us');
    };
    return (
        <>
            <SEO title="GMAT" />
            <Layout>
                <BreadcrumbOne
                    title="GMAT Coaching at FETC"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="GMAT"
                />
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={'GMAT'}
                    mTitle={'Graduate Management Admission Test'}
                    desc_1={"FETC's GMAT (Graduate Management Admission Test) coaching program is expertly crafted to help you excel in this crucial exam. The GMAT is essential for those aiming to enter top business schools and management programs worldwide. Our coaching thoroughly prepares you for all sections of the GMAT exam: Analytical Writing Assessment, Integrated Reasoning, Quantitative Reasoning, and Verbal Reasoning."}
                    desc_2={"We offer a balanced approach that includes comprehensive lessons, regular practice tests, and strategic feedback to sharpen your skills. Our focus is on understanding the GMAT's unique format, mastering the content, and developing strategies to enhance your test performance. At FETC, our dedicated instructors provide the expertise and support necessary to help you achieve your GMAT goals and advance your career in business and management."}
                    // subTitle={usaData.subTitle}
                    imgSrc={'/images/others/GRE.png'}

                />
                {/* <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white"> */}
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                {/* <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12">
                                        <SectionTitle
                                                classes="text-center"
                                                slogan=""
                                                title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    <CategoryThreeCustom
                                        items={gmatItems}
                                        customStyle={{justifyContent:'center'}}
                                    />


                                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div> */}

                                <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12" style={{marginBottom:'20px'}}>
                                        <SectionTitle
                                                classes="text-center"
                                                slogan=""
                                                title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    <CustomCounter
                                        funFacts={gmatBenefits} />



                                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none" style={{marginTop:'20px'}}>
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-17.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div>

                                <h4 className="title">Exam Format and Structure</h4>
                                <p>The GMAT consists of four sections: Analytical Writing Assessment, Integrated Reasoning, Quantitative Reasoning, and Verbal Reasoning. This computer-adaptive test is specifically designed to measure the skills required for success in business school and beyond. The GMAT's structure allows it to adapt to your ability level, providing a precise assessment of your skills.</p>

                                <h4 className="title">Who Needs to Take GMAT?</h4>
                                <p>The GMAT is required by many top business schools globally as part of their admissions process for
                                    MBA and other management programs. It is essential for candidates looking to demonstrate their
                                    readiness for the rigors of graduate management education and is often considered for scholarships
                                    and financial aid as well.
                                    </p>


                                <h4 className="title">Accepted Countries and Cost</h4>

                                <p>The GMAT is accepted by business schools in the USA, Europe, Asia, and beyond. The cost of the
                                    GMAT exam is approximately INR 23,000, making it a critical investment for those aiming to secure a
                                    place in a prestigious business program.
                                    </p>

                            
                               {/* <ContactFormComponent /> */}
                               <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                                    <button 
                                        className="rn-btn edu-btn mb--30" 
                                        type="button"
                                        onClick={() => handleClick()}>
                                        <span>Get in touch with us</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </Layout>
        </>
    )
}
export default Gmat;