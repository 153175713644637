import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactFormComponent from '../../components/contact/ContactFormComponent';
import { useNavigate } from 'react-router-dom';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import CustomCounter from '../../components/counterup/CustomCounter';
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout';

const TOEFL = () => {
    const navigate = useNavigate();
    const toeflItems = [
        {
            title: 'Complete TOEFL Preparation',
            numberOfCourses: 'Coverage of all exam sections with focused training.',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'Strategic Learning',
            numberOfCourses: 'Techniques to enhance performance in each section.',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'Regular Practice',
            numberOfCourses: 'Frequent mock tests with constructive feedback.',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'Supportive Environment',
            numberOfCourses: 'Encouragement and guidance from experienced instructors.',
            icon: 'icon-Class',
            link: '#'
        }
    ];
    const toeflBenefits = [
        {
            title: 'Complete TOEFL Preparation',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Strategic Learning',
            icon: 'icon-presentation',
            number: 100,
            suffix: '%'
        },
        {
            title: 'Regular Practice',
            icon: 'icon-trophy',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Supportive Environment',
            icon: 'icon-Open-book',
            number: 300,
            suffix: '+'
        }
    ];

    const handleClick = () => {
        navigate('/contact-us');
    };
    return (
        <>
            <SEO title="TOEFL" />
            <Layout>
                <BreadcrumbOne
                    title="TOEFL"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="TOEFL"
                />
                 <CustomHomeAbout
                    sectionFormat={'1'}
                    title={'TOEFL'}
                    mTitle={'Test of English as a Foreign Language'}
                    desc_1={'At FETC, our TOEFL coaching program is expertly crafted to help students achieve high scores. The TOEFL exam is essential for non-native English speakers aiming to study, work, or live in English-speaking countries. Our coaching covers all four sections of the TOEFL exam: Reading, Listening, Speaking, and Writing, ensuring you are well-prepared for each.'}
                    desc_2={'Our approach includes comprehensive lessons, regular practice tests, and feedback sessions to build your confidence and improve your English language skills. We focus on developing the techniques and strategies needed to tackle the TOEFL exam effectively, helping you to achieve the scores required by top universities and employers worldwide. At FETC, we are committed to providing the guidance and support you need to succeed in your TOEFL journey.'}
                    // subTitle={usaData.subTitle}
                    imgSrc={'/images/others/TOEFL.png'}

                />
                {/* <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white"> */}
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                {/* <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <SectionTitle
                                                classes="text-center"
                                                slogan=""
                                                title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    <CategoryThreeCustom
                                        items={toeflItems}
                                        customStyle={{justifyContent:'center'}}
                                    />


                                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12" style={{marginBottom:'20px'}}>
                                        <SectionTitle
                                                classes="text-center"
                                                slogan=""
                                                title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    <CustomCounter
                                        funFacts={toeflBenefits} />



                                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none" style={{marginTop:'20px'}}>
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-17.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div>

                                <h4 className="title">Exam Format and Structure</h4>
                                <p>TOEFL consists of four sections: Reading, Listening, Speaking, and Writing. Each section measures a different aspect of your academic English proficiency. The test is available only in a computer-based format, ensuring a standardized and secure testing experience for all candidates.</p>

                                <h4 className="title">Who Needs to Take TOEFL?</h4>
                                <p>TOEFL is required for non-native English speakers seeking admission to English-speaking universities and colleges. It is also often needed for professional certifications and immigration purposes. Whether you are pursuing higher education or professional opportunities, TOEFL is a vital step in demonstrating your English proficiency.</p>


                                <h4 className="title">Accepted Countries and Cost</h4>

                                <p>TOEFL is accepted by institutions in the USA, Canada, Australia, and more, making it a preferred choice for students and professionals. The cost of the TOEFL exam is approximately INR 16,000, reflecting its importance in achieving your educational and career goals.</p>

                            
                               {/* <ContactFormComponent /> */}
                               <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                                    <button 
                                        className="rn-btn edu-btn mb--30" 
                                        type="button"
                                        onClick={() => handleClick()}>
                                        <span>Get in touch with us</span>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </Layout>
        </>
    )
}
export default TOEFL;