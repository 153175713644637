import React,{ useState } from 'react';
import Slider from 'react-slick';
import { TestimonialFourParams } from '../../utils/script';
import { FaPlay } from 'react-icons/fa';
import FsLightbox from 'fslightbox-react';

const data = [
    {
        name: "Tina K. Leflore",
        details: "There are many variations of passages of Lorem Ipsum available, but the majority on have suffered alteration in some form, by a injected humour, or randomised.",
        thumbnail: `${process.env.PUBLIC_URL}./images/videopopup/Thumbnail.png`,
        VideoSrc: [`${process.env.PUBLIC_URL}./videos/Review_1.webm`],
        rating: 5
    },
    {
        name: "Kevin G. Curry",
        details: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis par.",
        thumbnail: `${process.env.PUBLIC_URL}./images/videopopup/thumbnail_2.png`,
        VideoSrc: [`${process.env.PUBLIC_URL}./videos/Review_2.mp4`],
        rating: 5
    },
    {
        name: "David M. Bard",
        details: "Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet dictum felis eu pede mollis pretium.",
        thumbnail: `${process.env.PUBLIC_URL}./images/videopopup/thumbnail_3.png`,
        VideoSrc: [`${process.env.PUBLIC_URL}./videos/Review_3.mp4`],
        rating: 5
    },
    {
        name: "David M. Bard",
        details: "Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet dictum felis eu pede mollis pretium.",
        thumbnail: `${process.env.PUBLIC_URL}./images/videopopup/thumbnail_4.png`,
        VideoSrc: [`${process.env.PUBLIC_URL}./videos/Review_4.mp4`],
        rating: 5
    },
    {
        name: "David M. Bard",
        details: "Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet dictum felis eu pede mollis pretium.",
        thumbnail: `${process.env.PUBLIC_URL}./images/videopopup/thumbnail_5.png`,
        VideoSrc: [`${process.env.PUBLIC_URL}./videos/Review_5.webm`],
        rating: 5
    },


];

const TestimonialFour = ( props ) => {
    const [toggler, setToggler] = useState( false );
    const [videoLink, setVideoLink] = useState('');
    const classes = `eduvibe-testimonial-four edu-testimonial-activation testimonial-item-3 mt--40 edu-slick-button ${props.className ? props.className : '' }`;
    return (
        <>
        <Slider
            className={classes}
            { ...TestimonialFourParams }
        >
            { data.map( ( item, i ) => (
                <div className="testimonial-card-box" key={ i }>
                    <div className="col-lg-12">
                            <div className="thumbnail video-popup-wrapper">
                                <img className="radius-small w-100" src={item.thumbnail} alt="Video PopUp Thumb" />
                                <button className="video-play-btn with-animation position-to-top video-popup-activation color-secondary size-60" onClick={ () => {
                                    setToggler( ! toggler );
                                    setVideoLink(item.VideoSrc)

                                } }>
                                    <span><FaPlay className="play-icon" /></span>
                                </button>
                            </div>
                        </div>
                </div>
            ) ) }
        </Slider>
            <FsLightbox 
                toggler={ toggler } 
                sources={ videoLink }
                maxYoutubeVideoDimensions={ { width: 900, height: 550 } }
            />
        </>
    )
}

export default TestimonialFour;