import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactFormComponent from '../../components/contact/ContactFormComponent';

const StudentVisa = () => {
    return (
        <>
            <SEO title="Student Visa" />
            <Layout>
                <BreadcrumbOne
                    title="Student Visa"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Student Visa"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                <div className="thumbnail mb--50 text-center">
                                    <img className="radius-small" style={{height:'500px'}} src="/images/others/studentVisa.jpg" alt="Privacy Policy" />
                                    <h2 className="title">~ Student Visa ~</h2>
                                </div>

                                <p>A Student Visa is a gateway to achieving your academic dreams in a foreign country. Whether you're aiming to study in the USA, UK, Canada, Australia, or any other country, securing the right visa is the first step towards your educational journey abroad.</p>
                                <h4 className="title">Why Choose a Student Visa?</h4>
                                <ul>
                                    <li><strong>World-Class Education:</strong> A Student Visa allows you to access world-renowned universities and culeges, offering programs that can enhance your career prospects globally.</li>
                                    <li><strong>Cultural Exposure: </strong>Studying abroad provides an opportunity to immerse yourself in a new culture, develop language skills, and build a global network of friends and professionals.</li>
                                    <li><strong>Personal Growth: </strong>Living in a new country helps develop independence, problem-solving skills, and adaptability, which are invaluable in today's globalized world.</li>
                                </ul>

                                <h4 className="title">Eligibility Criteria</h4>
                                <p>To obtain a Student Visa, you generally need:</p>
                                <ul>
                                    <li><strong>Acceptance Letter:</strong> An offer or acceptance letter from a recognized educational institution.</li>
                                    <li><strong>Proof of Funds:</strong> Demonstrating that you have sufficient funds to cover tuition fees, living expenses, and other costs during your stay.</li>
                                    <li><strong>Language Proficiency:</strong> Proof of English language proficiency, such as IELTS or TOEFL scores, may be required.</li>
                                    <li><strong>Health Insurance:</strong> Depending on the country, health insurance coverage may be mandatory.</li>
                                </ul>

                                <h4 className="title">Common Student Visa Requirements</h4>
                                <ul>
                                    <li><strong>Valid Passport:</strong> Your passport must be valid for at least six months beyond your intended stay.</li>
                                    <li><strong>Proof of Admission:</strong> Letter from the educational institution confirming your enrollment.</li>
                                    <li><strong>Financial Proof:</strong> Bank statements, scholarship letters, or sponsor letters showing you can support yourself.</li>
                                    <li><strong>Visa Application Form:</strong> Completed and signed application form.</li>
                                    <li><strong>Photographs:</strong> Passport-sized photos as per the country’s specifications.</li>
                                </ul>

                                <h4 className="title">Our Services</h4>
                                <p>We assist students in navigating the complex Student Visa application process, ensuring all documents are prepared accurately and submitted on time. Our services include:</p>
                                <ul>
                                    <li><strong>Visa Consultation:</strong> Personalized advice tailored to your study destination and academic goals.</li>
                                    <li><strong>Document Preparation:</strong> Guidance on gathering and preparing all required documents.</li>
                                    <li><strong>Application Submission:</strong> Support in completing and submitting your visa application.</li>
                                    <li><strong>Interview Preparation:</strong> Tips and mock interviews to help you feel confident during your visa interview.</li>
                                    <li><strong>Photographs:</strong> Passport-sized photos as per the country’s specifications.</li>
                                </ul>
                            
                                {/* <ContactFormComponent /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default StudentVisa;