import React from 'react'
import { useNavigate } from 'react-router-dom';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const StudyAbroad = () => {

    const navigate = useNavigate();

    const handleUsaClick = () => {
        navigate('/about-country-usa');
    };
    const handleAustraliaClick = () => {
        navigate('/about-country-australia');
    };
    const handleEuropeClick = () => {
        navigate('/about-country-europe');
    };
    const handleIrelandClick = () => {
        navigate('/about-country-ireland');
    };
    const handleUkClick = () => {
        navigate('/about-country-uk');
    };

    return (
        <>
            <SEO title="Privacy Policy" />
            <Layout>
                <BreadcrumbOne
                    title="Study Abroad"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Study Abroad"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                <div className="thumbnail mb--50 text-center">
                                    <img className="radius-small" src="/images/others/studyabroad.png" style={{ height: '500px' }} alt="Privacy Policy" />
                                    <h2 className="title">Study Abroad with FETC</h2>
                                </div>


                                <p>Unlock a world of opportunities with FETC, your trusted partner in pursuing education overseas. At
                                    FETC, we understand that studying abroad is more than just enrolling in a university—it's about
                                    embarking on a life-changing journey that shapes your future. We are dedicated to providing
                                    comprehensive and personalized support at every stage of your study abroad experience. From
                                    selecting the right course and university to guiding you through the intricacies of visa applications,
                                    FETC is here to make your dreams of international education a reality.
                                </p>

                                <p>
                                    Embarking on a journey to study abroad is a significant decision,
                                    one that can shape your academic and professional future in profound ways. At FETC, we understand the importance of making informed choices, and we are committed to guiding you every step of the way. Our comprehensive study abroad services span across all countries, ensuring that no matter where your aspirations take you, we can provide the support you need. From helping you select the right country and university to navigating the complexities of visa applications, FETC is dedicated to making your study abroad experience as seamless and successful as possible.
                                </p>
                                <p>Our expertise in international education and our partnerships with top institutions worldwide allow us to offer tailored advice and solutions that align with your goals. Whether you are aiming for a degree in the United States, exploring opportunities in Europe, or considering Australia’s dynamic academic environment, we provide personalized assistance that covers all aspects of your journey. With FETC by your side, you can focus on your studies and personal growth, knowing that the logistical details are in expert hands. Our track record of successful visa processes and satisfied students speaks to our commitment to your success.</p>

                                <h4 className="title">Explore Prime Study Destinations</h4>

                                <p>Choosing the right destination is crucial to your academic and career success. Each country offers
                                    unique advantages, and at FETC, we have the expertise to help you find the perfect fit. Explore our
                                    top study destinations below, and click on your preferred country to discover why it's the ideal place
                                    to further your education.</p>

                                <div className="row g-5 mt--20">


                                    <div className="col-lg-4 col-md-6">
                                        <div className="contact-address-card-2" style={{ cursor: 'pointer' }} onClick={handleUsaClick}>
                                            <div className="inner">
                                                <div className="icon">
                                                    <img src="/images/contact/contact-me/USA.png" alt="Icon Images" style={{height:'200px',filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))'}}/>
                                                </div>
                                                <div className="content">
                                                    <h6 className="title" style={{fontSize:'28px'}}>USA</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="contact-address-card-2" style={{ cursor: 'pointer' }} onClick={handleUkClick}>
                                            <div className="inner">
                                                <div className="icon">
                                                    <img src="/images/contact/contact-me/UK.png" alt="Icon Images" style={{height:'200px',filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))'}}/>
                                                </div>
                                                <div className="content">
                                                    <h6 className="title" style={{fontSize:'28px'}}>UK</h6>
                                                    {/* <p>328, 3rd Floor <br /> Radhika Optima Near Yamuna Chowk,<br />  Mota Varachha Surat- 394101</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="contact-address-card-2" style={{ cursor: 'pointer' }} onClick={handleIrelandClick}>
                                            <div className="inner">
                                                <div className="icon">
                                                    <img src="/images/contact/contact-me/ireland.png" alt="Icon Images" style={{height:'200px',filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))'}}/>
                                                </div>
                                                <div className="content">
                                                    <h6 className="title" style={{fontSize:'28px'}}>Ireland</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-center g-5 mt--20">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="contact-address-card-2" style={{ cursor: 'pointer' }} onClick={handleEuropeClick}>
                                            <div className="inner">
                                                <div className="icon">
                                                    <img src="/images/contact/contact-me/europe.png" alt="Icon Images" style={{height:'200px',filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))'}} />
                                                </div>
                                                <div className="content">
                                                    <h6 className="title" style={{fontSize:'28px'}}>Europe</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="contact-address-card-2" style={{ cursor: 'pointer' }} onClick={handleAustraliaClick}>
                                            <div className="inner">
                                                <div className="icon">
                                                    <img src="/images/contact/contact-me/australia.png" alt="Icon Images" style={{height:'200px',filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))'}}/>
                                                </div>
                                                <div className="content">
                                                    <h6 className="title" style={{fontSize:'28px'}}>Australia</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default StudyAbroad;