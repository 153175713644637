import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactMeForm from '../../components/contact/ContactMeForm';
import ContactFormComponent from '../../components/contact/ContactFormComponent';
import { useNavigate } from 'react-router-dom';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import CustomCounter from '../../components/counterup/CustomCounter';
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout';

const IELTS = () => {
    const navigate = useNavigate();
    // const ieltsItems = [
    //     {
    //         title: 'Comprehensive Skill Development',
    //         numberOfCourses: 'Focused training on all four IELTS components.',
    //         icon: 'icon-Schoolbag',
    //         link: '#'
    //     },
    //     {
    //         title: 'Expert Instructors',
    //         numberOfCourses: 'Guidance from experienced professionals.',
    //         icon: 'icon-student-read',
    //         link: '#'
    //     },
    //     {
    //         title: 'Practice-Oriented Approach',
    //         numberOfCourses: 'Regular mock tests and feedback.',
    //         icon: 'icon-Pencil',
    //         link: '#'
    //     },
    //     {
    //         title: 'Targeted Improvement',
    //         numberOfCourses: 'Exercises tailored to strengthen weak areas.',
    //         icon: 'icon-Class',
    //         link: '#'
    //     }
    // ];

    const IeltsBenefits = [
        {
            title: 'Comprehensive Skill Development',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Expert Instructors',
            icon: 'icon-presentation',
            number: 100,
            suffix: '%'
        },
        {
            title: 'Practice-Oriented Approach',
            icon: 'icon-trophy',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Targeted Improvement',
            icon: 'icon-Open-book',
            number: 300,
            suffix: '+'
        }
    ];

    const handleClick = () => {
        navigate('/contact-us');
    };
    return (
        <>
            <SEO title="IELTS" />
            <Layout>
                <BreadcrumbOne
                    title="IELTS"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="IELTS"
                />
                <CustomHomeAbout
                    sectionFormat={'1'} //2nd isn't looking goos fix it
                    title={'IELTS'}
                    mTitle={'International English Language Testing System'}
                    desc_1={'At FETC, our IELTS program is designed to provide students with the skills and strategies they need to excel in all four components of the exam: Listening, Reading, Writing, and Speaking. Our experienced instructors focus on enhancing your language abilities, ensuring that you are well-prepared to achieve the band score required for your study, work, or immigration goals.'}
                    desc_2={'We offer a structured approach that includes comprehensive practice tests, detailed feedback, and targeted exercises to strengthen your weak areas. At FETC, we believe in fostering a positive and encouraging learning environment, where students can grow their confidence and improve their English proficiency. Our goal is to help you reach your highest potential and open doors to opportunities globally.'}
                    // subTitle={usaData.subTitle}
                    imgSrc={'/images/others/IELTS.png'}

                />
                {/* <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white"> */}
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                
                                <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12" style={{marginBottom:'20px'}}>

                                        <SectionTitle
                                            classes="text-center"
                                            slogan=""
                                            title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    {/* <CategoryThreeCustom
                                        items={ieltsItems}
                                        customStyle={{justifyContent:'center'}}
                                    /> */}
                                    <CustomCounter
                                        funFacts={IeltsBenefits}/>

                                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none" style={{marginTop:'20px'}}>
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-17.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div>

                                <h4 className="title">Exam Format and Structure</h4>
                                <p>The IELTS exam comprises four sections: Listening, Reading, Writing, and Speaking. Each section is designed to evaluate your English language proficiency in academic or general contexts. The test is available in both pen-and-paper and computer-based formats, providing flexibility to candidates.</p>

                                <h4 className="title">Who Needs to Take IELTS?</h4>
                                <p>IELTS is essential for individuals seeking to study, work, or migrate to English-speaking countries. It is required by universities for admission, by employers for job applications, and by immigration authorities for visa processing. The test is a key step for anyone planning to pursue opportunities abroad.</p>


                                <h4 className="title">Accepted Countries and Cost</h4>

                                <p>IELTS is accepted in the UK, USA, Australia, Canada, New Zealand, and other countries for educational, professional, and immigration purposes. The cost of the exam is INR 17,000, making it a crucial investment for your future abroad.</p>

                            
                               {/* <ContactFormComponent /> */}
                               <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                                    <button 
                                        className="rn-btn edu-btn mb--30" 
                                        type="button"
                                        onClick={() => handleClick()}>
                                        <span>Get in touch with us</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </Layout>
        </>
    )
}
export default IELTS;