import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactMeForm from '../../components/contact/ContactMeForm';
import ContactFormComponent from '../../components/contact/ContactFormComponent';
import { useNavigate } from 'react-router-dom';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import SectionTitle from '../../components/sectionTitle/SectionTitle';

const PTEVersant = () => {
    const navigate = useNavigate();
    const ptevItems = [
        {
            title: 'Focused PTE Versant Preparation',
            numberOfCourses: 'Emphasis on Speaking and Listening sections.',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'Performance-Enhancing Techniques',
            numberOfCourses: 'Strategies to improve fluency and accuracy.',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'Continuous Practice',
            numberOfCourses: 'Regular mock exams with constructive feedback.',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'Professional Support',
            numberOfCourses: 'Experienced instructors dedicated to your success in the test.',
            icon: 'icon-Class',
            link: '#'
        }
    ];

    const handleClick = () => {
        navigate('/contact-us');
    };
    return (
        <>
            <SEO title="PTEVersant" />
            <Layout>
                <BreadcrumbOne
                    title="PTE - Versant"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="PTEVersant"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                <div className="thumbnail mb--50 text-center">
                                    <img className="radius-small" style={{height:'250px'}} src="/images/others/Pte.png" alt="Privacy Policy" />
                                    <h2 className="title">~ PTE ~</h2>
                                </div>

                                <p>FETC's PTE Versant coaching program is tailored to help students and professionals demonstrate their English proficiency effectively. The PTE Versant test, known for its quick and accurate assessment of spoken English, is crucial for those seeking to validate their language skills in academic and professional contexts. Our coaching covers all elements of the PTE Versant test, focusing on Speaking and Listening skills, ensuring you are well-prepared to excel.</p>
                                <p>Our program includes focused training sessions, frequent practice exams, and detailed feedback to enhance your performance on the PTE Versant test. We concentrate on developing your spoken English fluency and accuracy, which are key to achieving a high score. At FETC, we provide a structured learning environment where our expert instructors guide you through each step of your PTE Versant preparation journey.</p>
                                
                                <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12">
                                        <SectionTitle
                                                classes="text-center"
                                                slogan=""
                                                title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    <CategoryThreeCustom
                                        items={ptevItems}
                                        customStyle={{justifyContent:'center'}}
                                    />


                                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div>

                                <h4 className="title">Exam Format and Structure</h4>
                                <p>PTE-Versant evaluates your spoken English skills, including pronunciation, fluency, sentence mastery,
                                    and vocabulary. The test is conducted entirely over the phone or on a computer, with automated
                                    scoring ensuring impartial and quick results. This test is designed to assess your ability to
                                    communicate effectively in everyday and workplace situations.
                                    </p>

                                <h4 className="title">Who Needs to Take PTE-Versant?</h4>
                                <p>PTE-Versant is suitable for non-native English speakers who need to demonstrate their spoken
                                    English proficiency for job applications, internal company assessments, or language certification
                                    purposes. It's particularly useful for professionals in customer service, call centers, and other
                                    communication-intensive roles.
                                    </p>


                                <h4 className="title">Accepted Countries and Cost</h4>

                                <p>PTE-Versant is recognized by many multinational companies and educational institutions across the
                                    globe. The test is affordable, with costs varying depending on the location and administration
                                    method, typically around INR 5,000 to INR 8,000.
                                    </p>

                            
                               {/* <ContactFormComponent /> */}
                               <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                                    <button 
                                        className="rn-btn edu-btn mb--30" 
                                        type="button"
                                        onClick={() => handleClick()}>
                                        <span>Get in touch with us</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default PTEVersant;