import axios from 'axios';
import Cookies from 'js-cookie';

const token = Cookies.get('authToken');
const api = axios.create({
    baseURL: 'https://login.fetc.in/api/method',
    headers: {
        'Content-Type': 'application/json',
        // Add other headers if needed
    },
    withCredentials: true, // If cookies or authentication are required
});

export default api;

export const contactUs = async (name, email, message, number, topic) => {
    try {
        const response = await api.post(`/lms.lms.utils.contact_us`, null, {
            params: {
                name,
                email,
                message,
                number,
                topic
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error sending contact us message:', error);
        throw error;
    }
};

export const login = async (email, password) => {
    try {
        const formData = new URLSearchParams();
        formData.append('email', email);
        formData.append('password', password);

        const response = await axios.post(`https://login.fetc.in/api/method/lms.lms.utils.login`, formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // 'Cookie': 'full_name=Guest; sid=Guest; system_user=no; user_id=Guest; user_image='
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
};
export const fetchCourseCount = () => {
    return api.get('/lms.lms.utils.get_course_chapter_count', {
      headers: {
        'Authorization': token,
      },
    });
  };
  
  // API to fetch chapter progress
  export const fetchProgress = () => {
    return api.get('/lms.lms.utils.get_chapter_progress_fetc?course=syzygy', {
      headers: {
        'Authorization': token,
      },
    });
  };
  
  // API to fetch average course completion progress
  export const fetchAvgCourseCompleted = () => {
    return api.get('/lms.lms.utils.get_course_progress_fetc?course=SYZYGY', {
      headers: {
        'Authorization': token,
      },
    });
  };
  export const fetchGuestCourses = () => {
    return axios.get('/lms.lms.utils.get_courses');
  };