import React from 'react';
import SectionTitle from '../sectionTitle/SectionTitle';
import HowItWorksOne from '../how-it-works/HowItWorksOne';
import ScrollAnimation from 'react-animate-on-scroll';

const HomeFiveProgress = () => {
    return (
        <div className="eduvibe-home-five-progress  edu-service-area edu-section-gap bg-image">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes = "text-center"
                            slogan = ""
                            title = "Unlock Your Learning Potential with SyZyGy"
                        />
                          <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}
                            >

                        <div className="section-title">
                        <p className="description text-center">At SyZygY, we make learning flexible and accessible. Start by selecting from our wide range of courses tailored to your needs. Purchase your chosen course, and you’re ready to begin your learning journey at your own pace. And soon, upon successful completion, you’ll be able to earn a certificate to showcase your achievements and enhance your career prospects. Stay tuned for this exciting new feature, coming soon to SyZygY.</p>
                        </div>
                            </ScrollAnimation>
                    </div>
                </div>

                <HowItWorksOne />

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-07-02.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-04-07.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-13-11.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeFiveProgress;