import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import CourseFilterSidebar from './pages/course/CourseFilterSidebar';
import AboutUsOne from './pages/innerpages/AboutUsOne';
import AboutUsTwo from './pages/innerpages/AboutUsTwo';
import AboutUsThree from './pages/innerpages/AboutUsThree';
import BlogGridOne from './pages/blog/BlogGridOne';
import BlogGridTwo from './pages/blog/BlogGridTwo';
import BlogGridThree from './pages/blog/BlogGridThree';
import BlogCarousel from './pages/blog/BlogCarousel';
import BlogLoadMore from './pages/blog/BlogLoadMore';
import BlogStandard from './pages/blog/BlogStandard';
import BlogGridRightSidebar from './pages/blog/BlogGridRightSidebar';
import BlogGridLeftSidebar from './pages/blog/BlogGridLeftSidebar';
import BlogDetailsOne from './pages/detailspages/BlogDetailsOne';
import BlogDetailsTwo from './pages/detailspages/BlogDetailsTwo';
import BlogDetailsThree from './pages/detailspages/BlogDetailsThree';
import CategoryArchive from './pages/archive/CategoryArchive';
import TagArchive from './pages/archive/TagArchive';
import AuthorArchive from './pages/archive/AuthorArchive';
import ComingSoon from './pages/innerpages/ComingSoon';
import ContactMe from './pages/innerpages/ContactMe';
import ContactUs from './pages/innerpages/ContactUs';
import CourseOne from './pages/course/CourseOne';
import CourseTwo from './pages/course/CourseTwo';
import CourseThree from './pages/course/CourseThree';
import CourseFour from './pages/course/CourseFour';
import CourseFive from './pages/course/CourseFive';
import CourseFilterOne from './pages/course/CourseFilterOne';
import CourseFilterTwo from './pages/course/CourseFilterTwo';
import CourseCarousel from './pages/course/CourseCarousel';
import CourseLoadMore from './pages/course/CourseLoadMore';
import CourseCategoryArchive from './pages/archive/CourseCategoryArchive';
import CourseDetails from './pages/detailspages/CourseDetails';
import CourseDetailsTwo from './pages/detailspages/CourseDetailsTwo';
import EventGrid from './pages/innerpages/EventGrid';
import EventList from './pages/innerpages/EventList';
import EventLoadMore from './pages/innerpages/EventLoadMore';
import EventCarousel from './pages/innerpages/EventCarousel';
import EventDetails from './pages/detailspages/EventDetails';
import Faq from './pages/innerpages/Faq';
import GalleryGrid from './pages/innerpages/GalleryGrid';
import GalleryMasonry from './pages/innerpages/GalleryMasonry';
import GalleryLoadMore from './pages/innerpages/GalleryLoadMore';
import HomeOne from './pages/homepages/HomeOne';
import HomeTwo from './pages/homepages/HomeTwo';
import HomeThree from './pages/homepages/HomeThree';
import HomeFour from './pages/homepages/HomeFour';
import HomeFive from './pages/homepages/HomeFive';
import InstructorPageOne from './pages/innerpages/InstructorPageOne';
import InstructorPageTwo from './pages/innerpages/InstructorPageTwo';
import InstructorPageThree from './pages/innerpages/InstructorPageThree';
import LandingDemo from './pages/LandingDemo';
import LoginRegister from './pages/innerpages/LoginRegister';
import Pricing from './pages/innerpages/Pricing';
import PrivacyPolicy from './pages/innerpages/PrivacyPolicy';
import PurchaseGuide from './pages/innerpages/PurchaseGuide';
import Testimonial from './pages/innerpages/Testimonial';
import InstructorDetails from './pages/detailspages/InstructorDetails';
import Error from './pages/innerpages/Error';

// Import Css Here 
import './assets/scss/style.scss';
import CourseDetailsThree from './pages/detailspages/CourseDetailsThree';
import PR_Form from './pages/innerpages/PrForm';
import PrForm from './pages/innerpages/PrForm';
import Vision from './pages/innerpages/VisionPage';
import PteGeneral from './pages/innerpages/PteGeneral';
import Helpline from './pages/innerpages/Helpline';
import StudentVisa from './pages/innerpages/StudentVisa';
import VisitorVisa from './pages/innerpages/VisitorVisa';
import Selt from './pages/innerpages/Selt';
import PTEVersant from './pages/innerpages/PTEVersant';
import IELTS from './pages/innerpages/IELTS';
import TOEFL from './pages/innerpages/TOEFL';
import SAT from './pages/innerpages/SAT';
import GreGmat from './pages/innerpages/GreGmat';
import AirTicket from './pages/innerpages/AIrTicket';
import StudyAbroad from './pages/innerpages/StudyAbroad';
import {AboutCountryUsa} from './pages/innerpages/AboutCountryUsa';
import { AboutCountryIreland } from './pages/innerpages/AboutCountryIreland';
import { AboutCountryUk } from './pages/innerpages/AboutCountryUk';
import { AboutCountryEurope } from './pages/innerpages/AboutCountryEurope';
import { AboutCountryAustralia } from './pages/innerpages/AboutCountryAustralia';
import PTE_A from './pages/innerpages/PTE_A';
import Gmat from './pages/innerpages/Gmat';
import SeltExam from './pages/innerpages/SeltExam';
import PTEVersantExam from './pages/innerpages/PTEVersantExam';
import ProfilePage from './pages/detailspages/ProfilePage';
import { AuthProvider } from './provider/AuthProvider';
import ProtectedRoute from './provider/ProtectedRoute';
import CoursePage from './pages/course/CoursePage';

function App() {
    return (
        /* for cookies*/
        <AuthProvider> 
            <Router>
                <ScrollToTop>
                    <Routes>
                        <Route exact path='/' element={<HomeThree/>}/>
                        {/* <Route exact path='/' element={<LandingDemo/>}/> */}
                        <Route exact path={`${process.env.PUBLIC_URL + '/home-1'}`} element={<HomeOne/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/home-2'}`} element={<HomeTwo/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/home-3'}`} element={<HomeThree/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/home-4'}`} element={<HomeFour/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/home-5'}`} element={<HomeFive/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-us-1'}`} element={<AboutUsOne/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-us-2'}`} element={<AboutUsTwo/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-us'}`} element={<AboutUsThree/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-country-usa'}`} element={<AboutCountryUsa/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-country-ireland'}`} element={<AboutCountryIreland/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-country-uk'}`} element={<AboutCountryUk/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-country-europe'}`} element={<AboutCountryEurope/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/about-country-australia'}`} element={<AboutCountryAustralia/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/coming-soon'}`} element={<ComingSoon/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/contact-us'}`} element={<ContactMe/>}/>
                        {/* <Route exact path={`${process.env.PUBLIC_URL + '/contact-us'}`} element={<ContactUs/>}/> */}
                        <Route exact path={`${process.env.PUBLIC_URL + '/helpline'}`} element={<Helpline/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/courses'}`} element={<CoursePage/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-1'}`} element={<CourseOne/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-2'}`} element={<CourseTwo/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-3'}`} element={<CourseThree/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-4'}`} element={<CourseFour/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-5'}`} element={<CourseFive/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-filter-1'}`} element={<CourseFilterOne/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-filter-2'}`} element={<CourseFilterTwo/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-carousel'}`} element={<CourseCarousel/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-load-more'}`} element={<CourseLoadMore/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-category/:slug'}`} element={<CourseCategoryArchive/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-details/:id'}`} element={<CourseDetails/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-details-two/:id'}`} element={<CourseDetailsTwo/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-details-three/:id'}`} element={<CourseDetailsThree/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/course-filter-sidebar'}`} element={<CourseFilterSidebar />}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/event-grid'}`} element={<EventGrid/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/event-list'}`} element={<EventList/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/event-load-more'}`} element={<EventLoadMore/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/event-carousel'}`} element={<EventCarousel/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/event-details/:id'}`} element={<EventDetails/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/faq'}`} element={<Faq/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/air-tickets'}`} element={<AirTicket/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/gallery-grid'}`} element={<GalleryGrid/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/gallery-masonry'}`} element={<GalleryMasonry/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/gallery-load-more'}`} element={<GalleryLoadMore/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/instructor-one'}`} element={<InstructorPageOne/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/instructor-two'}`} element={<InstructorPageTwo/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/instructor-three'}`} element={<InstructorPageThree/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/pricing'}`} element={<Pricing/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/privacy-policy'}`} element={<PrivacyPolicy/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/student-visa'}`} element={<StudentVisa/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/study-abroad'}`} element={<StudyAbroad/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/selt'}`} element={<Selt/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/selt-exam'}`} element={<SeltExam/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/visitor-visa'}`} element={<VisitorVisa/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/pr_Form'}`} element={<PrForm/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/vision'}`} element={<Vision/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/pte-versant'}`} element={<PTEVersant/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/pte-versant-exam'}`} element={<PTEVersantExam/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/pte-a'}`} element={<PTE_A/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/pte-general'}`} element={<PteGeneral/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/ielts'}`} element={<IELTS/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/toefl'}`} element={<TOEFL/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/sat'}`} element={<SAT/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/gre'}`} element={<GreGmat/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/gmat'}`} element={<Gmat/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/purchase-guide'}`} element={<PurchaseGuide/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/testimonial'}`} element={<Testimonial/>}/>
                        {/* <Route exact path={`${process.env.PUBLIC_URL + '/instructor-details/:slug'}`} element={<InstructorDetails/>}/> */}
                        <Route exact path={`${process.env.PUBLIC_URL + '/profile'}`} element={<ProtectedRoute><ProfilePage/></ProtectedRoute>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-1'}`} element={<BlogGridOne/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-2'}`} element={<BlogGridTwo/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-3'}`} element={<BlogGridThree/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/blog-carousel'}`} element={<BlogCarousel/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/blog-load-more'}`} element={<BlogLoadMore/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/blog-standard'}`} element={<BlogStandard/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-right-sidebar'}`} element={<BlogGridRightSidebar/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-left-sidebar'}`} element={<BlogGridLeftSidebar/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/login'}`} element={<LoginRegister/>}/>

                        <Route exact path={`${process.env.PUBLIC_URL + '/blog-details/:id'}`} element={<BlogDetailsOne/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/blog-details-left-sidebar/:id'}`} element={<BlogDetailsTwo/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/blog-details-right-sidebar/:id'}`} element={<BlogDetailsThree/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/category/:slug'}`} element={<CategoryArchive/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/tag/:slug'}`} element={<TagArchive/>}/>
                        <Route exact path={`${process.env.PUBLIC_URL + '/author/:slug'}`} element={<AuthorArchive/>}/>
                        <Route path='*' element={<Error/>}/>
                    </Routes>
                </ScrollToTop>
            </Router>
        </AuthProvider>
    );
}

export default App;
