import React from 'react';
import SEO from '../../common/SEO';
import LoginForm from '../../components/form/LoginForm';
import { useNavigate } from 'react-router-dom';

const LoginRegister = () => {

    const navigate = useNavigate();
    const goBack = ()=>{

        navigate('/');
    }
    return (
        <>
            <SEO title="Login" />
            {/* <Layout>  turn it on if the warning is causing issues*/}

                {/* <BreadcrumbOne 
                    title="Login"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Login"
                /> */}

                <div className="login-register-page-wrapper bg-color-white">
                    <div className="container checkout-page-style">
                        <div className="row g-5">
                            <div className="col-lg-6 mx-auto">
                                <div style={{display:'flex',justifyContent:'center'}}>

                                    <img src="/images/logo/FETC-logo.png" alt="Main Logo" style={{filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.3))',width:'200px'}} />
                                </div>
                                <LoginForm />
                            </div>
                            {/* <div className="col-lg-6">
                                <RegisterForm />
                            </div> */}
                            <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                                    <button 
                                        className="rn-btn edu-btn mb--30" 
                                        type="button"
                                        onClick={goBack}>
                                        <span>Go Back</span>
                                    </button>
                                </div>
                        </div>
                    </div>
                </div>
            {/* </Layout> */}
        </>
    )
}

export default LoginRegister;