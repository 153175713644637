import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import CustomHomeBanner from '../home-four/CustomHomeBanner';

const AboutUsThreeMission = () => {
    return (
        <>
        <div className="eduvibe-about-three-mission edu-mission-vision-area border-bottom-1 bg-color-white">

            <CustomHomeBanner 
                title={'Our Mission'}
                Mtitle={'Elevating Educational Standards'}
                desc={'Our mission is to transform the work study settle journey by offering support and expertise in Counselling, Applications and Student/WP visas. By fostering a supportive, unbiased and professional approach we aim to build lasting trust and empower our clients to achieve their global aspirations with confidence and excellence.'}
                imgSrc={'/images/about/about-07/mission1.png'}
            />
        </div>
        <div className="eduvibe-about-three-mission edu-mission-vision-area border-bottom-1 bg-color-white" style={{marginTop:'-10px'}}>

            <CustomHomeBanner 
                title={'Our Vision'}
                Mtitle={'The Story of Our Founder'}
                desc={'FETC is a vision and dedication of Ms. Dilkhush, who has been a leading figure in ESL education in Surat since 2012. Her extensive experience in the field, post her CELTA Graduation from Cambridge in 2011. Evolved in developing various learning tools for schools as was associated with Govt. Projects like SCOPE, GKS, RKCL and MKCL. With the acquired expertise of imparting training to over 200+ Engineering colleges under the leadership of Shri Narendra. Modi as Guj-CM in 2010 via Vizag (Gandhinagar) and participating in TKT programs extensively in Gujarat crossing over 5000+ teachers from Vapi MAA foundation to Gandhinagar SCOPE project.  Later, expanding her resources she participated in NAFSA, IEF, AISEC, NET24, ICEF, and many more to provide study, work and settle option to all. Hence, has now polished like a diamond to shine and stand class apart; as she hails from Surat- the city of diamonds.'}
                imgSrc={'/images/about/about-07/vision1.png'}
            />
        </div>
        </>

    )
}

export default AboutUsThreeMission;