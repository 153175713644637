import React from 'react'
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout'
import CustomCounter from '../../components/counterup/CustomCounter'
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import { Navigate } from 'react-router-dom';

export const AboutCountryAustralia = () => {

    const australiaBenefits = [
        {
            title: 'High-Quality Education System',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Research and Practical Focus',
            icon: 'icon-presentation',
            number: 100,
            suffix: '%'
        },
        {
            title: 'Global Recognition',
            icon: 'icon-trophy',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Post Study Work ',
            icon: 'icon-Open-book',
            number: 300,
            suffix: '+'
        }
    ];

    const handleClick = () => {
        Navigate('/contact-us');
    };

    const australiaItems = [
        {
            title: 'CQ University Australia',
            numberOfCourses: '',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'Griffith College',
            numberOfCourses: '',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'Flinders University',
            numberOfCourses: '',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'University of Canberra',
            numberOfCourses: '',
            icon: 'icon-Class',
            link: '#'
        },
        {
            title: 'The University of Adelaide College',
            numberOfCourses: '',
            icon: 'icon-Setting',
            link: '#'
        },
        {
            title: 'University of Melbourne',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Australian National University ',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'University of Queensland',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Victoria University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'La Trobe University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Charles Darwin University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Deakin University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        }
    ];

    const australiaData = {
        title: 'Australia',
        mTitle: 'Why Study in Australia?',
        desc_1: "Australia’s education system is globally recognized for its high standards and innovative approach to teaching. With a reputation for excellence, Australia offers a diverse range of programs across its world-class universities, making it an attractive destination for students seeking to study abroad. The country's strong emphasis on research and practical skills equips students with the tools they need to excel in their chosen fields. Whether you’re interested in engineering, medicine, business, or the arts, Australian institutions provide an education that is both comprehensive and forward-thinking.",
        desc_2: 'In addition to its academic strengths, Australia is known for its multicultural society and welcoming atmosphere. The natural beauty of the country, combined with its vibrant cities and outdoor lifestyle, offers a unique balance between academic rigor and quality of life. Studying in Australia not only enhances your knowledge but also provides a life-changing experience that broadens your horizons and prepares you for global opportunities.',
        subTitle: 'Features of Australia',
        funFacts: australiaBenefits,
        items: australiaItems
    }
    return (
        <>
            <SEO title={`About ${australiaData.title}`} />
            <Layout>
                <BreadcrumbOne
                    title="Study Abroad"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Study Abroad"
                />
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={australiaData.title}
                    mTitle={australiaData.mTitle}
                    desc_1={australiaData.desc_1}
                    desc_2={australiaData.desc_2}
                    subTitle={australiaData.subTitle}
                    imgSrc={'/images/about/about-07/australia.png'}
                />
                <CustomCounter
                    funFacts={australiaData.funFacts}
                />

                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                classes="text-center"
                                slogan=""
                                title="Universities"
                            />
                        </div>
                    </div>

                    <CategoryThreeCustom
                        items={australiaData.items}
                    />


                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                    <button
                        className="rn-btn edu-btn mb--30"
                        type="button"
                        onClick={() => handleClick()}>
                        <span>Get in touch with us</span>
                    </button>
                </div>

            </Layout>

        </>
    )
}
