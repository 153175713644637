import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const items = [
    {
        title: 'English Proficiency ',
        numberOfCourses: 'Enhance your language skills with us to improve English proficiency.',
        icon: 'icon-Schoolbag',
        link: '#'
    },
    {
        title: 'Personalized Career Counseling',
        numberOfCourses: 'Receive expert guidance to navigate your career path, job opportunities, career planning.',
        icon: 'icon-student-read',
        link: '#'
    },
    {
        title: 'Study Abroad, Scholarships & Visas',
        numberOfCourses: 'Explore global educational opportunities with our support for studying abroad.',
        icon: 'icon-Pencil',
        link: '#'
    },
    {
        title: 'Comprehensive E-learning -Syzygy',
        numberOfCourses: 'Access high-quality online learning designed to enhance your English proficiency.',
        icon: 'icon-Class',
        link: '#'
    },
    {
        title: 'Financial planning',
        numberOfCourses: 'Plan your finances with our expert service to support your education and career goals.',
        icon: 'icon-Setting',
        link: '#'
    },
    {
        title: 'Syllabus Mapping & SOPs',
        numberOfCourses: 'Our services ensure that your study plan is well-organized and your SOPs meet academic requirements.',
        icon: 'icon-Mind',
        link: '#'
    },
];

const CategoryThreeCustom = ( { wrapperClass, styleClass, items, customStyle } ) => {
    return (
        <div className={ `row ${ wrapperClass || 'g-5 mt--25' }` } style={customStyle}>
            { items?.map( ( data , i ) => (
                <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className={ `col-lg-4 col-md-6 col-12 ${ styleClass ? styleClass : '' }` }
                    animateOnce={ true }
                    key={ i }
                >
                    <div className="service-card service-card-5">
                        <div className="inner" style={{height:'182px'}}>
                            <div className="icon">
                                <i className={ data.icon }></i>
                            </div>
                            <div className="content">
                                <h6 className="title"><a href={ data.link }>{ data.title }</a></h6>
                                <p className="description">{ data.numberOfCourses }</p>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
        </div>
    )
}

export default CategoryThreeCustom;