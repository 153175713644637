import React,{ forwardRef } from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';

const CustomHomeAbout = forwardRef((props,ref) => {
    return (
        <div ref={ref} className=" eduvibe-home-four-about edu-about-area about-style-2 edu-section-gap bg-color-white">
            {props.sectionFormat === '1' ? 
            <div className="container eduvibe-animated-shape">
                <div className="row row--50">
                    <div className="col-lg-6">
                        <div className="about-image-gallery">
                            <div className="eduvibe-about-1-img-wrapper">
                                <img className="image-1" style={{objectFit:'cover'}} src={props.imgSrc} alt="About Images" />
                                <span className="eduvibe-about-blur">
                                    <img src="/images/about/about-07/about-blur.png" alt="About Blur" />
                                </span>
                            </div>
                            {/* <div className="circle-image-wrapper">
                                <img className="image-2" src="/images/about/-07/about-02.jpeg" alt="About Images" />
                                <div className="circle-image">
                                    <span></span>
                                </div>
                            </div> */}
                            {/* <div className="finished-session">
                                <div className="inner">
                                    <div className="text">2.98</div>
                                    <span className="finished-title">Finished <br /> Sessions</span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inner mt_md--40 mt_sm--40">
                            <SectionTitle
                                classes = "text-start"
                                slogan = {props.title}
                                title = {props.mTitle}
                            />
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}
                            >
                            <div className="feature-style-6 mt--40">
                                <div className="edu-feature-list color-variation-1">
                                    <div className="content">
                                        {/* <h6 className="title">English Language Training</h6> */}
                                        <p className="description">{props.desc_1}</p>
                                    </div>
                                </div>

                                <div className="edu-feature-list color-variation-2">
                                    <div className="content">
                                        {/* <h6 className="title">English Language Exam Centre</h6> */}
                                        <p className="description">{props.desc_2}</p>
                                    </div>
                                </div>
                                
                            </div>
                            </ScrollAnimation>
{/* 
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                className="read-more-btn mt--40"
                                animateOnce={true}
                            >
                                <Link className="edu-btn" to={process.env.PUBLIC_URL + "/about-us"}>Explore FETC's Mission<i className="icon-arrow-right-line-right"></i></Link>
                            </ScrollAnimation> */}

                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-11-05.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-08-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-30.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-1">
                        <span className="shape-dot"></span>
                    </div>
                </div>
            </div>
            :
            <div className="container eduvibe-animated-shape">
                <div className="row row--50">
                <div className="col-lg-6">
                        <div className="inner mt_md--40 mt_sm--40">
                            <SectionTitle
                                classes = "text-start"
                                slogan = {props.title}
                                title = {props.mTitle}
                            />
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}
                            >
                            <div className="feature-style-6 mt--40">
                                <div className="edu-feature-list color-variation-1">
                                    <div className="content">
                                        {/* <h6 className="title">English Language Training</h6> */}
                                        <p className="description">{props.desc_1}</p>
                                    </div>
                                </div>

                                <div className="edu-feature-list color-variation-2">
                                    <div className="content">
                                        {/* <h6 className="title">English Language Exam Centre</h6> */}
                                        <p className="description">{props.desc_2}</p>
                                    </div>
                                </div>
                                
                            </div>
                            </ScrollAnimation>
{/* 
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                className="read-more-btn mt--40"
                                animateOnce={true}
                            >
                                <Link className="edu-btn" to={process.env.PUBLIC_URL + "/about-us"}>Explore FETC's Mission<i className="icon-arrow-right-line-right"></i></Link>
                            </ScrollAnimation> */}

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-image-gallery">
                            <div className="eduvibe-about-1-img-wrapper">
                                <img className="image-1" src="/images/about/about-07/about-01.jpeg" alt="About Images" />
                                <span className="eduvibe-about-blur">
                                    <img src="/images/about/about-07/about-blur.png" alt="About Blur" />
                                </span>
                            </div>
                            {/* <div className="circle-image-wrapper">
                                <img className="image-2" src="/images/about/about-07/about-02.jpeg" alt="About Images" />
                                <div className="circle-image">
                                    <span></span>
                                </div>
                            </div> */}
                            {/* <div className="finished-session">
                                <div className="inner">
                                    <div className="text">2.98</div>
                                    <span className="finished-title">Finished <br /> Sessions</span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-11-05.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-08-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-30.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-1">
                        <span className="shape-dot"></span>
                    </div>
                </div>
            </div>
            }
        </div>
    )
})

export default CustomHomeAbout;