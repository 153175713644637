import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactMeForm from '../../components/contact/ContactMeForm';

const Helpline = () => {
    return (
        <>
            <SEO title="HelpLine Number" />
            <Layout>
                <BreadcrumbOne 
                    title="HelpLine Number"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="HelpLine"
                />
                <div className="eduvibe-contact-me-top edu-contact-me-area about-me-1 edu-section-gap bg-color-white">
                    <div className="container eduvibe-animated-shape">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center">
                                        <span className="pre-title"> Reach Out to Us</span>
                                        <h3 className="title">We're here to assist you every step of the way.</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="row g-5 mt--20">

                                <div className="col-lg-4 col-md-6">
                                    <div className="contact-address-card-2">
                                        <div className="inner">
                                            <div className="icon">
                                                <img src="/images/contact/contact-me/whatsapp.png" alt="Icon Images" />
                                            </div>
                                            <div className="content">
                                                <h6 className="title">General Inquiry</h6>
                                                <p><span className="subtitle">Mobile: </span><a href="tel: (+91) 9033347201"> (+91) 9033347201</a></p>
                                                {/* <p><span className="subtitle">Hotline: </span><a href="tel: 1800 - 1102">1800 - 1102</a></p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6">
                                    <div className="contact-address-card-2">
                                        <div className="inner">
                                            <div className="icon">
                                                <img src="/images/contact/contact-me/whatsapp.png" alt="Icon Images" />
                                            </div>
                                            <div className="content">
                                                <h6 className="title">Bardoli</h6>
                                                <p><span className="subtitle">Mobile: </span><a href="tel: (+91) 9033347202"> (+91) 9033347202</a></p>
                                                {/* <p><span className="subtitle">Hotline: </span><a href="tel: 1800 - 1102">1800 - 1102</a></p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6">
                                    <div className="contact-address-card-2">
                                        <div className="inner">
                                            <div className="icon">
                                                <img src="/images/contact/contact-me/whatsapp.png" alt="Icon Images" />
                                            </div>
                                            <div className="content">
                                                <h6 className="title">Rajkot</h6>
                                                <p><span className="subtitle">Mobile: </span><a href="tel: (+91) 9033347203"> (+91) 9033347203</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row g-5 mt--20">

                                <div className="col-lg-6 col-md-6">
                                    <div className="contact-address-card-2">
                                        <div className="inner">
                                            <div className="icon">
                                                <img src="/images/contact/contact-me/whatsapp.png" alt="Icon Images" />
                                            </div>
                                            <div className="content">
                                                <h6 className="title">Counselling and fees related query</h6>
                                                <p><span className="subtitle">General Inquiry: </span><a href="tel: (+91) 9033347204"> (+91) 9033347204</a></p>
                                                {/* <p><span className="subtitle">Hotline: </span><a href="tel: 1800 - 1102">1800 - 1102</a></p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="contact-address-card-2">
                                        <div className="inner">
                                            <div className="icon">
                                                <img src="/images/contact/contact-me/whatsapp.png" alt="Icon Images" />
                                            </div>
                                            <div className="content">
                                                <h6 className="title">Application related query </h6>
                                                <p><span className="subtitle">Mobile: </span><a href="tel: (+91) 9033347205"> (+91) 9033347205</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                <div className="shape-image shape-image-1">
                                    <img src="/images/shapes/shape-03-01.png" alt="Shape Thumb" />
                                </div>
                                <div className="shape-image shape-image-2">
                                    <img src="/images/shapes/shape-05-06.png" alt="Shape Thumb" />
                                </div>
                                <div className="shape-image shape-image-3">
                                    <img src="/images/shapes/shape-14-03.png" alt="Shape Thumb" />
                                </div>
                                <div className="shape-image shape-image-4">
                                    <img src="/images/shapes/shape-05-03.png" alt="Shape Thumb" />
                                </div>
                                <div className="shape-image shape-image-5">
                                    <img src="/images/shapes/shape-01-03.png" alt="Shape Thumb" />
                                </div>
                            </div>
                        </div>
                </div>
            </Layout>
        </>
    )
}
export default Helpline;