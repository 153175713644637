import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import AboutFive from '../../components/about/AboutFive';
import LogoOne from '../../components/logos/LogoOne';
import HomeFiveProgress from '../../components/home-five/HomeFiveProgress';
import AboutUsThreeMission from '../../components/about-us-three/AboutUsThreeMission';
import AboutUsThreeInstructor from '../../components/about-us-three/AboutUsThreeInstructor';
import CounterUpOne from '../../components/counterup/CounterUpOne';
import TestimonialSectionTwo from '../../components/testimonial-section/TestimonialSectionTwo';
import Vision from './VisionPage';
import HomeCustomService from '../../components/home-three/HomeCustomService';
import TestimonialSectionCustomise from '../../components/testimonial-section/TestimonialSectionCustomise';
import HomeFourAbout from '../../components/home-four/HomeFourAbout';

const AboutUsThree = () => {
    return (
        <>
            <SEO title="About Us" />
            <Layout>
                <BreadcrumbOne 
                    title="About Us"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="About Us"
                />

                <Vision/>

                <HomeFourAbout 
                page={'about'}/>
                {/* <AboutFive wrapperClass="edu-section-gapTop" /> */}

                {/* <LogoOne separator="disable" /> */}

                <HomeFiveProgress />

                <AboutUsThreeMission />

                <HomeCustomService 
                    title={'Why Choose Us?'}
                    mTitle={'What All We Provide'}
                />

                <AboutUsThreeInstructor />

                <CounterUpOne />

                <div style={{marginBottom:'50px'}}/>
                {/* <TestimonialSectionTwo classes="counterup-overlay-top" /> */}
                {/* <TestimonialSectionCustomise /> */}

            </Layout>
        </>
    )
}

export default AboutUsThree;