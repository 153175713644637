import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactFormComponent from '../../components/contact/ContactFormComponent';
import { useNavigate } from 'react-router-dom';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import CustomCounter from '../../components/counterup/CustomCounter';
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout';

const GreGmat = () => {
    const navigate = useNavigate();
    const greItems = [
        {
            title: 'Comprehensive Preparation',
            numberOfCourses: 'In-depth coverage of Verbal, Quantitative, and Analytical Writing sections.',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'Strategic Learning',
            numberOfCourses: 'Techniques tailored to maximize performance in each section.',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'Frequent Practice',
            numberOfCourses: 'Regular mock exams with targeted feedback.',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'Expert Instruction',
            numberOfCourses: 'Support and guidance from knowledgeable instructors dedicated to your success.',
            icon: 'icon-Class',
            link: '#'
        }
    ];
    const greBenefits = [
        {
            title: 'Comprehensive Preparation',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Strategic Learning',
            icon: 'icon-presentation',
            number: 100,
            suffix: '%'
        },
        {
            title: 'Frequent Practice',
            icon: 'icon-trophy',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Expert Instruction',
            icon: 'icon-Open-book',
            number: 300,
            suffix: '+'
        }
    ];

    const handleClick = () => {
        navigate('/contact-us');
    };
    return (
        <>
            <SEO title="GRE" />
            <Layout>
                <BreadcrumbOne
                    title="GRE Coaching at FETC"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="GRE"
                />
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={'GRE'}
                    mTitle={'Graduate Record Examination'}
                    desc_1={'At FETC, our GRE (Graduate Record Examination) coaching program is meticulously designed to help you achieve top scores. The GRE is a critical exam for students aspiring to pursue graduate studies in universities around the world. Our coaching covers all three sections of the GRE exam: Verbal Reasoning, Quantitative Reasoning, and Analytical Writing, ensuring you are comprehensively prepared for each.'}
                    desc_2={"Our approach combines rigorous academic training with strategic techniques, offering extensive practice tests and personalized feedback to refine your skills. We focus on helping you understand the test structure, master the content, and apply effective test-taking strategies. At FETC, our experienced instructors are committed to guiding you through your GRE preparation, providing the tools and support you need to reach your academic goals."}
                    // subTitle={usaData.subTitle}
                    imgSrc={'/images/others/GRE.png'}

                />
                {/* <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white"> */}
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">

                                {/* <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12">
                                        <SectionTitle
                                                classes="text-center"
                                                slogan=""
                                                title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    <CategoryThreeCustom
                                        items={greItems}
                                        customStyle={{justifyContent:'center'}}
                                    />


                                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div> */}

                                <div className="container eduvibe-animated-shape">
                                    <div className="row">
                                        <div className="col-lg-12" style={{marginBottom:'20px'}}>
                                        <SectionTitle
                                                classes="text-center"
                                                slogan=""
                                                title="Key Features"
                                            />
                                        </div>
                                    </div>

                                    <CustomCounter
                                        funFacts={greBenefits} />



                                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none" style={{marginTop:'20px'}}>
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-17.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div>

                                <h4 className="title">Exam Format and Structure</h4>
                                <p>The GRE consists of three sections: Verbal Reasoning, Quantitative Reasoning, and Analytical Writing. The test is designed to assess your readiness for graduate-level studies by measuring your critical thinking, analytical writing, and problem-solving skills. The GRE is offered in a computer-based format, with an option for a paper-based test in certain regions.</p>

                                <h4 className="title">Who Needs to Take GRE?</h4>
                                <p>The GRE is required by many graduate schools and business programs worldwide. It is essential for
                                    students aiming to pursue master's or doctoral degrees, as well as certain MBA programs. The GRE is
                                    also used by institutions to assess eligibility for scholarships and financial aid.
                                    </p>


                                <h4 className="title">Accepted Countries and Cost</h4>

                                <p>The GRE is accepted by thousands of graduate programs in the USA, Canada, Europe, Australia, and
                                    more. The cost of the GRE exam is approximately INR 21,000, reflecting its significance in advancing
                                    your academic and professional career.
                                    </p>

                            
                               {/* <ContactFormComponent /> */}
                               <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                                    <button 
                                        className="rn-btn edu-btn mb--30" 
                                        type="button"
                                        onClick={() => handleClick()}>
                                        <span>Get in touch with us</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </Layout>
        </>
    )
}
export default GreGmat;