import React from 'react';
import SEO from '../../common/SEO';
import BannerThree from '../../components/banner/BannerThree';
import HomeThreeService from '../../components/home-three/HomeThreeService';
import AboutFive from '../../components/about/AboutFive';
import HomeThreeCourse from '../../components/home-three/HomeThreeCourse';
import AboutSix from '../../components/about/AboutSix';
import FooterOne from '../../common/footer/FooterOne';
import FreeConsultation from '../innerpages/FreeConsultation';
import TestimonialSectionOne from '../../components/testimonial-section/TestimonialSectionOne';
import BannerFour from '../../components/banner/BannerFour';
import HomeFourAbout from '../../components/home-four/HomeFourAbout';
import TestimonialSectionCustomise from '../../components/testimonial-section/TestimonialSectionCustomise';
import HeaderOne from '../../common/header/HeaderOne';

const HomeThree = () => {
    return (
        <>
            <SEO title="FETC" />
             
            <HeaderOne styles="header-transparent header-style-3" />
            {/* <HeaderTwo styles="header-transparent header-style-3" /> */}
            {/* <HeaderTwo styles="header-transparent header-style-2" /> */}

            {/* <BannerThree /> */}
            <BannerFour/>
            
            <HomeThreeService />

            <HomeFourAbout />
            {/* <AboutFive /> */}

            {/* <HomeThreeCourse /> */}
            
            <AboutSix />

            {/* <TestimonialSectionCustomise /> */}
            {/* <TestimonialSectionTwo /> */}

            <FreeConsultation />

            {/* <HomeThreeEvent /> */}

            {/* <NewsLetterFormTwo /> */}

            <FooterOne />
        </>
    )
}

export default HomeThree;