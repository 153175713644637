import React from 'react'

const Vision = () => {
    return (
        <>
        <div className="edu-privacy-policy-area edu-privacy-policy edu-section-custom-gap bg-color-white">
            <div className="container">
                <div className="row g-5">
                    <div className="content">
                        <div className="thumbnail mb--50 text-center">
                            <h2 className="title">Empowering Your Vision for International Opportunities</h2>
                        </div>
                        
                        <p className="text-center">At FETC, our mission is to empower individuals in realizing their aspirations of studying, working, and thriving abroad. We offer a comprehensive suite of services, from mastering language proficiency exams to navigating the complexities of visa processes. Our commitment is to provide the tools, expertise, and unwavering support needed to ensure your journey toward global success is both seamless and successful.</p>

                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Vision;